import { Box, Typography } from "@material-ui/core";
import classNames from "classnames/bind";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { imageUrl, productUrl } from "../../../helpers/common";
import useStyles from "./styles";
import "../../../index.css";
import { getItemList } from "../../../api/items";

const VariantColors = ({ product }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productVariants, setProductVariants] = useState([]);

  useEffect(() => {
    if (product.id) {
      getItemList(
        `products?ProductsSearch[variants_of]=${product.id}&group_by=item_color&sort=id`
      ).then((json) => {
        let data = []

        json?.data?.forEach((item) => {
          const match = data?.find(e => e?.item_color === item?.item_color)

          if (!match) {
            data.push(item)
          }
        })

        setProductVariants(data);
        window.scrollTo({
          top: 200,
          behavior: "smooth",
        });
      });
    }
  }, [dispatch, product.id]);

  if (productVariants?.length === 0) {
    return null
  }

  return (
    <Box>
      <Typography variant='h5' className={classes.productName}>
        Finish
      </Typography>

      <Box className={classes.colorSec} mt={5}>
        {productVariants.map((variant, index) => (
          <Box textAlign='center' key={index}>
            <Box
              key={index}
              className={classNames(classes.colorBox, {
                [classes.activeColor]:
                  variant.item_color === product.item_color,
              })}
            >
              <img
                src={imageUrl(
                  variant.main_image,
                  "products/" + variant.id,
                  "120x120-"
                )}
                style={{ objectFit: "cover", height: 60, borderRadius: "50%" }}
                alt={variant.main_image.name}
                onClick={() => navigate(productUrl(variant))}
              />
              <br />
            </Box>
            {variant.item_color}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default VariantColors;
