import React, { useCallback } from "react";
import { useDispatch } from 'react-redux';
import CommonButton from "../CommonButton";
import { updateCartData, findProductInCart } from "../../../cart";
import { showErrorSnackbar, showSuccessSnackbar,  } from "../../../actions/snackbar";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { CART_UPDATED } from "../../../constants/actionTypes";
import useStyles from './styles';

function AddToCartButton({ cartData, product, navigate, customer, quantity = 1 }) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const addProductToCart = useCallback((product) => {

        const newCartData = updateCartData(cartData, product, quantity);

        dispatch({ type: CART_UPDATED, payload: newCartData });

        dispatch(showSuccessSnackbar("product is added to cart"));

    }, [dispatch, cartData, quantity]);

    // const removeProductFromCart = useCallback((product) => {

    //     const newCartData = updateCartData(cartData, product, 0);

    //     dispatch({ type: CART_UPDATED, payload: newCartData });

    //     dispatch(showErrorSnackbar("product is removed from cart"));

    // }, [dispatch, cartData]);

    const goToCart = () => {
        if(customer?.mobile_number){
            navigate('/cart')
        }else{
            dispatch(showErrorSnackbar("You need to login first"));
            navigate('/login')
        }
    }


    if (product.available_stock <= 0 || product.selling_price === 0) {
        return <CommonButton
                 rounded
                 disabled
                 style={{ borderRadius: 28, color: 'black' }}
                 className={classes.commonButton}
                 >
                OUT OF STOCK </CommonButton>
    }

    if (findProductInCart(cartData, product)) {
        return <CommonButton
                 rounded
                 style={{ borderRadius: 28 }}
                 className={classes.commonButton}
                 onClick={goToCart}
                 >
                Go to Cart</CommonButton>
    }

    return <CommonButton
            rounded
            style={{ borderRadius: 28 }}
            onClick={() => addProductToCart(product)}
            className={classes.commonButton}
            startIcon={<ShoppingCartIcon />}
            >
        Add to Cart</CommonButton>
}

export default AddToCartButton;