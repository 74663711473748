import { Box, Grid, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { callOrdersCheckoutApi } from "../../actions/orders";
import { showErrorSnackbar } from "../../actions/snackbar";
import { authKey_local_storage_key } from "../../helpers/common";
import ShippingDetailsForm from "./ShippingDetailsForm";
import ShippingProductDisplay from "./ShippingProductDisplay";
import useStyles from "./styles";

export default function Shipping() {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const authToken = localStorage.getItem(authKey_local_storage_key);
    setToken(authToken);
  }, []);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("shivamCartProducts"));
    if (items && Array.isArray(items)) setProducts(items);
  }, []);

  const handleAddressSubmit = useCallback(
    (customer) => {
      const items = products.map((d) => {
        return {
          item_id: d.id,
          quantity: d.qty,
        };
      });

      let coupon_code = customer.coupon_code;
      let customer_remarks = customer?.customer_remarks;

      delete customer.coupon_code;


      const payload = {
        ...customer,
        items,
        customer_remarks,
        coupon_code,
      }

      delete payload.company_name
      delete payload.gst_aadhaar_number
      delete payload.transport_name
      delete payload.transport_number

      // debugger

      setLoading(true);
      callOrdersCheckoutApi(dispatch, token, payload)
        .then((response) => {
          if (response.status === 200 && response.data) {
            navigate(`/checkout/${response.data.order.id}`, {
              state: { order: response.data },
            });
          } else {
            dispatch(
              showErrorSnackbar("Something went wrong. Please try again")
            );
          }
        })
        .catch((error) => {
          let errorMsg = error.message;
          if (error?.response?.data?.message) {
            errorMsg = error.response.data.message;
          }
          dispatch(showErrorSnackbar(errorMsg));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, products, token, navigate]
  );

  return (
    <Box maxWidth={1200} p={3} m="48px auto" overflow="hidden">
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Typography
            variant="h5"
            color="textSecondary"
            style={{ marginBottom: 32 }}
          >
            Shipping Details
          </Typography>
          <ShippingDetailsForm
            isLoading={isLoading}
            handleAddressSubmit={handleAddressSubmit}
            classes={classes}
            customer_remarks={location?.state?.remark}
          />
        </Grid>
        <ShippingProductDisplay />
      </Grid>
    </Box>
  );
}
