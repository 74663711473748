import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  shippingField: {
    margin: '16px 0'
  },
  regions: {
    fontFamily: 'Product Sans',
    height: 56,
    marginRight: 32,
    width: '100%',
    color: '#666',
    fontSize: 16,
    borderRadius: 5
  },
  items: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderLeft: '1px solid #aaa'
    },
    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid #aaa'
    }
  },
  images: {
    width: '100%',
    objectFit: 'cover',
    border: '1px solid #aaa',
    borderRadius: 6
  },
  accordionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 7,
    color: '#424242',
    fontSize: 17,
    '& svg': {
      marginTop: -3
    }
  }
}));

export default useStyles;
