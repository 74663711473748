import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  GridList,
  GridListTile,
  InputAdornment,
  TextField
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/KeyboardArrowRight";
import Skeleton from "@material-ui/lab/Skeleton";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProductList,
  getReviews
} from "../../../actions/products";
import { getSettingsInfo } from "../../../actions/settings";
import { getItemList } from "../../../api/items";
import { imageUrl } from "../../../helpers/common";
import "../../../index.css";
import AddToCartButton from "../../Common/AddToCartButton";
import RatingAndReview from "./RatingAndReview";
import SimilarProducts from "./SimilarProducts";
import VariantColors from "./VariantColors";
import VariantSets from "./VariantSets";
import VariantSizes from "./VariantSizes";
import useStyles from "./styles";

const SingleProduct = () => {
  let { productId } = useParams();

  const [product, setProduct] = useState({});

  useEffect(() => {
    if (productId) {
      getItemList(`products/${productId}`).then((json) => {
        setProduct(json.data);
      });
    }
  }, [productId]);

  const varientLength = product.media?.split(",").length;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [currIndex, setIndex] = useState(0);
  const customer = useSelector((state) => state.auth.profile);
  const cartData = useSelector((state) => state.cart.data);

  useEffect(() => {
    dispatch(getSettingsInfo());
  }, [dispatch]);

  useEffect(() => {
    if (product?.id) {
      dispatch(getReviews(product?.id));
    }
  }, [product, dispatch]);


  const leftArrowClicked = () => {
    if (currIndex > 0) {
      setIndex(currIndex - 1);
    } else {
      setIndex(0);
    }
  };

  const rightArrowClicked = () => {
    if (varientLength - 1 > currIndex) {
      setIndex(currIndex + 1);
    }
  };

  useEffect(() => {
    dispatch(
      getProductList(
        `ProductsSearch[category_id]=${product.category_id}&group_by=item_color`
      )
    );
  }, [dispatch, product]);

  // TODO: move out main image and its thumbnails to a separate component
  // TODO: use productUrl function everywhere to get the product url. Similary create for categoryUrl also.

  if (!product.id) {
    return <CircularProgress className={classes.loader} />;
  }

  return (
    <Box overflow='hidden'>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <Box mb={3} position='relative' className={classes.mainImgBox}>
            {varientLength > 1 ? (
              <div style={{ width: "100%" }}>
                <button
                  className={`carousel-btn left ${
                    currIndex === 0 ? "disabled" : null
                  }`}
                  onClick={leftArrowClicked}
                >
                  <img
                    src={require("../../../assets/images/left-arrow.png")}
                    alt='left'
                  />
                </button>
                <button
                  className={`carousel-btn right ${
                    varientLength - 1 === currIndex ? "disabled" : null
                  }`}
                  onClick={rightArrowClicked}
                >
                  <img
                    src={require("../../../assets/images/right-arrow.png")}
                    alt='right'
                  />
                </button>
              </div>
            ) : null}
            {product.media ? (
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "",
                    isFluidWidth: true,
                    src: imageUrl(
                      product.media?.split(",")?.[currIndex],
                      "products/" + product.id,
                      "600x600-"
                    ),
                  },
                  largeImage: {
                    src: imageUrl(
                      product.media?.split(",")?.[currIndex],
                      "products/" + product.id,
                      "original"
                    ),
                    width: 900,
                    height: 1080,
                  },
                  style: { zIndex: 99 },
                  isHintEnabled: true,
                }}
              />
            ) : (
              <Skeleton
                className={classes.skeleton}
                variant='rect'
                width='100%'
                height={400}
              />
            )}
          </Box>
          {varientLength > 1 ? (
            <GridList
              cellHeight={80}
              spacing={3}
              className={`productGridlist ${classes.gridList}`}
              cols={5}
            >
              {product.media?.split(",")?.map((tile, index) => (
                <GridListTile
                  key={tile}
                  cols={1}
                  className={classes.gridListTile}
                >
                  <img
                    src={imageUrl(tile, "products/" + product.id)}
                    style={{ objectFit: "cover" }}
                    alt={tile.title}
                    onClick={() => setIndex(index)}
                  />
                </GridListTile>
              ))}
            </GridList>
          ) : null}

          {product?.media?.split(",")?.map((tile, index) => (
            <img
              key={index}
              style={{ visibility: "hidden" }}
              width={1}
              src={imageUrl(tile, "products/" + product?.id, "600x600-")}
              alt={tile.title}
            />
          ))}

          <VariantColors product={product}  />
          <VariantSizes product={product}  />
          <VariantSets product={product}  />
          
        </Grid>
        <Grid item sm={6}>
          <Box fontSize={32} fontWeight={700}>
            {capitalize(product.name)}
          </Box>
          {product.sku ? (
            <Box fontSize={22} my={1}>{`SKU: ${product.sku}`}</Box>
          ) : (
            <Skeleton
              className={classes.skeleton}
              variant='rect'
              width={210}
              height={30}
            />
          )}
          <Box style={{ display: "flex", alignItems: "center" }}>
            {product ? (
              <Box
                fontWeight={700}
                fontSize={25}
                color='#283e77'
              >{`Rs. ${product?.selling_price}`}</Box>
            ) : (
              <Skeleton
                className={classes.skeleton}
                variant='rect'
                width={210}
                height={30}
              />
            )}
            {product.mrp !== product.selling_price && product.mrp !== 0 ? (
              <Box
                fontSize={18}
                style={{ textDecoration: "line-through", marginLeft: 10 }}
                color='#666'
              >{`MRP: Rs. ${product.mrp}`}</Box>
            ) : null}
          </Box>

          {product?.item_color && (
            <Box className={classes.additional_fields_text}>
              <b>Finish:</b> {product?.item_color}
            </Box>
          )}

          {product?.item_size && (
            <Box className={classes.additional_fields_text}>
              <b>Size:</b> {product?.item_size}
            </Box>
          )}

          {product?.item_size && (
            <Box className={classes.additional_fields_text}>
              <b>Item package quantity:</b> {product?.item_set}
            </Box>
          )}

          <Box
            my={2}
            fontFamily={"Product Sans"}
            dangerouslySetInnerHTML={{ __html: product.description }}
          />

          <Box my={2} display='flex' alignItems='center'>
            <TextField
              label='Quantity'
              variant='outlined'
              value={quantity}
              type='number'
              onChange={(e) => setQuantity(e.target.value)}
              style={{ marginRight: 12 }}
              classes={{ root: classes.zipInput }}
              className={classes.inputBtn}
              inputProps={{ min: 1, max: parseInt(product.sku, 10) }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>Qty:</InputAdornment>
                ),
              }}
            />

            <AddToCartButton
              cartData={cartData}
              customer={customer}
              product={product}
              navigate={navigate}
            />
          </Box>


          {product?.highlights?.trim() && (
            <>
              <Box fontSize={22} fontWeight={700} mt={3}>
                Product Highlights
              </Box>
              {product?.highlights?.split("\n").map((highlight) => (
                <Box
                  key={highlight}
                  display='flex'
                  my={2}
                  alignItems='center'
                  fontFamily={"Product Sans"}
                >
                  <ArrowRightAltIcon
                    style={{
                      borderRadius: 15,
                      color: "#fff",
                      background: "#283e77",
                      fontSize: 17,
                    }}
                  />
                  <Box ml={1} fontWeight={700} fontSize={15}>
                    {highlight}
                  </Box>
                </Box>
              ))}
            </>
          )}

          {product?.attributes?.trim() && (
            <>
              <Box mt={4} fontSize={22} fontWeight={700}>
                Product Attributes
              </Box>
              <Box>
                {product.attributes?.split("\n").map((tag) => (
                  <Chip key={tag} className={classes.chip} label={tag}></Chip>
                ))}
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      <SimilarProducts product={product} />

      <RatingAndReview product={product} />
    </Box>
  );
};

export default SingleProduct;
