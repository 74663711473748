import { combineReducers } from 'redux';

import menus from './menus';
import categories from './categories';
import products from './products';
import snackbar from './snackbar';
import banners from './banners';
import contents from './contents';
import auth from './customer';
import orders from './orders';
import cart from './cart';

const appReducers = combineReducers({
  menus,
  categories,
  snackbar,
  products,
  banners,
  contents,
  auth,
  orders,
  cart
});

export default appReducers;
