/* eslint-disable linebreak-style */
import React from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Layout from "./components/Layout";
import { useEffect } from "react";
import { getCustomerDetails, logout } from "./actions/customer";
import { showErrorSnackbar } from "./actions/snackbar";
import { customerId_local_storage_key } from "./helpers/common";
import { useNavigate } from "react-router-dom";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#283e77",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      contrastText: "#666",
    },
  },
  typography: {
    fontFamily: "Product Sans",
  },
});

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerId = localStorage.getItem(customerId_local_storage_key);
  

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerDetails(customerId)).then((res) => {
        if (res?.message?.includes("401") || res?.message?.includes("403")) {
          dispatch(logout());
          dispatch(showErrorSnackbar("Session expired. Please login again."));
          navigate("/login");
        }
      });
    }
  }, [customerId, dispatch, navigate]);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <Layout />
    </MuiThemeProvider>
  );
}

export default App;
