import { Box, Typography } from "@material-ui/core";
import classNames from "classnames/bind";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { productUrl } from "../../../helpers/common";
import useStyles from "./styles";
import "../../../index.css";
import { getItemList } from "../../../api/items";

const VariantSets = ({ product }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productVariants, setProductVariants] = useState([]);

  useEffect(() => {
    if (product.id) {
      getItemList(
        `products?ProductsSearch[variants_of]=${product.id}&ProductsSearch[item_size]=${product.item_size}&ProductsSearch[item_color]=${product.item_color}&sort=id`
      ).then((json) => {
        let data = [];
        json?.data?.forEach((item) => {
          const match = data?.find((e) => e?.item_set === item?.item_set);

          if (!match) {
            data.push(item);
          }
        });

        setProductVariants(data);
      });
    }
  }, [dispatch, product?.id, product]);

  if (productVariants?.length === 0) {
    return null;
  }

  return (
    <Box mt={3}>
      <Typography variant='h5' className={classes.productName}>
        Rate & Quantity
      </Typography>
      <Box className={classes.setSec}>
        {productVariants.map((variant, index) => (
          <div
            className={classNames(classes.setBox, {
              [classes.activeSize]: variant.id === product.id,
            })}
            key={index}
            onClick={() => navigate(productUrl(variant))}
          >
            <div className={classes.item_set}>
              {variant?.item_set || "Pack of 1"}
            </div>
            <div className={classes.price}>Rs.{variant?.selling_price}</div>
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default VariantSets;
