import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { truncate } from '../../../helpers/common'

import useStyles from './styles';

const Products = ({ image, title, price, discountedPrice }) => {
  const classes = useStyles();

  let priceText = <Box fontSize={24} pl={1}>&#x20b9;{discountedPrice}</Box>

  if ( parseFloat( discountedPrice ) > 0 ) {
    priceText = <React.Fragment>
      <Box fontSize={16} color="#666" style={{ textDecoration: 'line-through' }}>&#x20b9;{price}</Box>
      <Box fontSize={24} pl={1}>&#x20b9;{discountedPrice}</Box>
    </React.Fragment>
  }

  return (
    <Box width={'100%'}>
      <Box>
        <img src={image} alt={title} className={classes.productImage} />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
        <Box textAlign="left" className={classes.productBoxTitleContainer} >
          <Box fontSize={18} className={classes.productBoxTitle} >
          {truncate(title, 20)} </Box>
          <Box display="flex" alignItems="center">
            {priceText}
          </Box>
        </Box>
        <IconButton className={classes.cartButton}><ShoppingCartIcon /></IconButton>
      </Box>
    </Box>
  )
}
export default Products;
