import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  carouselItem: {
    width: "100%",
    display: "grid",
    height: 450,
    [theme.breakpoints.down("md")]: { height: 300 },
    [theme.breakpoints.down("sm")]: { height: 240 },
    [theme.breakpoints.down("xs")]: { height: 150 },
    "& > img": {
      height: 450,
      [theme.breakpoints.down("md")]: { height: 300 },
      [theme.breakpoints.down("sm")]: { height: 240 },
      [theme.breakpoints.down("xs")]: { height: 115 },
      width: "100%",
      objectFit: "cover"
    },
    [theme.breakpoints.down("xs")]: { height: 120,  },
    cursor: "pointer"
  },
  bannerCarouselDotList: {
    marginBottom: "20px !important",
    [theme.breakpoints.down("xs")]: { marginBottom: "5px !important" },
  },
  carouselItemContent: {
    width: "80%",
    maxWidth: 1120,
    margin: "auto",
    zIndex: 99
  },
  button: {
    fontSize: 20,
    width: 220,
    borderRadius: 25
  }
}));
