import {
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  UPDATE_PROFILE_SUCCESS,
  FORGET_PASSWORD_SUCCESS,
} from "../constants/actionTypes";
import { authKey_local_storage_key, customerId_local_storage_key, userInfo_local_storage_key } from "../helpers/common";

const INITIAL_STATE = { profile: { verified: false, cart: [] }, errors: null };

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return {
        ...state, profile: { ...state.profile, ...action.payload.data }, errors: null
      }
    case SIGNUP_FAILURE:
      return {
        ...state, errors: action.payload.response.data?.[0]?.message
      }
    case LOGIN_SUCCESS:
      localStorage.setItem(authKey_local_storage_key, action.payload.token);
      localStorage.setItem(userInfo_local_storage_key, JSON.stringify(action.payload));
      localStorage.setItem(customerId_local_storage_key, action.payload.customer.id);
      // localStorage.setItem('shivamCartProducts', (action.payload.customer.cart_data !== "") ? action.payload.customer.cart_data : "[]");
      return { ...state, profile: { ...action.payload.customer, cart: action.payload.customer.cart_data !== "" ? JSON.parse(action.payload.customer.cart_data) : [], verified: true } };
    case LOGIN_FAILURE:
      return {
        ...state, errors: action.payload.error
      }
    case LOGOUT_SUCCESS:
      localStorage.removeItem(authKey_local_storage_key);
      localStorage.removeItem(customerId_local_storage_key);
      localStorage.removeItem(userInfo_local_storage_key);
      // localStorage.removeItem('shivamCartProducts');
      return {
        ...state, profile: { verified: false }
      }
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state, profile: { ...action.payload.data, cart: JSON.parse(action.payload.data.cart_data), verified: true }
      }
    case GET_CUSTOMER_FAILURE:
      localStorage.removeItem('shivamCartProducts');
      return {
        ...state, profile: { verified: false }, errors: action.payload.message
      }
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state, profile: { ...state.profile, ...action.payload.data}
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state, profile: { ...state.profile, ...action.payload.data }
      }
    default:
      return state;
  }
}
