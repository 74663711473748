import {
  CHECKOUT_ORDER_SUCCESS,
  CHECKOUT_ORDER_FAILURE,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  GET_ALL_ORDER_SUCCESS,
  GET_ALL_ORDER_FAILURE,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE
} from '../constants/actionTypes';
import { createAction } from 'redux-actions';
import * as Order from '../api/orders';
import axios from "axios";
import { server_url } from '../helpers/common';

const checkoutOrderSuccess = createAction(CHECKOUT_ORDER_SUCCESS);
const checkoutOrderFailure = createAction(CHECKOUT_ORDER_FAILURE);

export function checkoutOrder(params) {
  return dispatch => Order.checkoutOrder(params)
    .then((json) => {
      dispatch(checkoutOrderSuccess(json));
      return json;
    })
    .catch((error) => {
      dispatch(checkoutOrderFailure(error));
      return error;
    })
}

const placeOrderSuccess = createAction(PLACE_ORDER_SUCCESS);
const placeOrderFailure = createAction(PLACE_ORDER_FAILURE);

export function placeOrder(params) {
  return dispatch => Order.placeOrder(params)
    .then((json) => {
      dispatch(placeOrderSuccess(json));
      return true;
    })
    .catch((error) => {
      dispatch(placeOrderFailure(error));
      return false
    });
}

const getAllOrderSuccess = createAction(GET_ALL_ORDER_SUCCESS);
const getAllOrderFailure = createAction(GET_ALL_ORDER_FAILURE);

export function getAllOrder() {
  return dispatch => Order.getAllOrders()
    .then(json => dispatch(getAllOrderSuccess(json)))
    .catch(error => dispatch(getAllOrderFailure(error)));
}

const getOrderDetailsSuccess = createAction(GET_ORDER_DETAILS_SUCCESS);
const getOrderDetailsFailure = createAction(GET_ORDER_DETAILS_FAILURE);

export function getOrderDetails(orderId) {
  return dispatch => Order.getOrderDetails(orderId)
    .then((json) => {
      dispatch(getOrderDetailsSuccess(json));
      return true;
    })
    .catch(error => {
      dispatch(getOrderDetailsFailure(error));
      return false;
    });
}


export const callOrdersCheckoutApi = async (dispatch, token, requestBody) => {
  return axios.post(
    `${server_url}/orders/checkout`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getPaymentGateways = async (token) => {
  return axios.get(`${server_url}/orders/payment_gateways?sort=display_order`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createPayment = async (token, requestBody) => {
  return axios.post(`${server_url}/orders/payment`, requestBody, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const verifyPayment = async (token, requestBody) => {
  return axios.post(`${server_url}/orders/verify_payment`, requestBody, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
