import { makeStyles } from "@material-ui/core";
import footerBg from "../../../assets/images/footer-bg.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontFamily: "Product Sans",
  },
  gridContainer: {
    margin: "auto",
    maxWidth: 1120,
    width: "100%",

    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  footerBackground: {
    background: `#1c5892 url(${footerBg}) top center`,
    backgroundSize: '100%',
    paddingTop: 60,
    [theme.breakpoints.down("md")]: {
      background: `#1c5892`,
    },
  },
  footerMenuItem: {
    cursor: "pointer",
    color: "#eee !important",
    marginTop: 16,
    marginBottom: 16,
  },
  footerLinks: {
    color: "#eee !important",
    marginBottom: 14,
    textDecoration: "none",
  },
  socialIcons: {
    marginRight: 8,
    cursor: "pointer",
  },
  footerDarkStrip: {
    backgroundColor: "#0b3054",
    letterSpacing: '1px',
    textAlign: 'center',
    padding: '18px 15px',
    "& > *": { backgroundColor: "#0b3054" },
  },
  contactIcons: { marginRight: 6 },
  partnerIcons: {
    height: 28,
    margin: "0 3px",
    background: "#00000000",
    [theme.breakpoints.down("sm")]: {
      height: 24,
    },
    "& img": {
      height: "100%",
      border: "1px solid #00000000",
      borderRadius: 3,
    },
  },
  partnerIconsLeft: {
    height: 25,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  footerMenuHeader: {
    // marginTop: 30,
    marginBottom: 30,
    fontFamily: "Product Sans",
    color: "#fff",
  },
  copyrightText: {
    fontFamily: "Raleway",
    textAlign: "center",
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
  },
  imgFluid: {
    maxWidth: "100%",
    height: "auto",
    display: "block",
  },
}));

export default useStyles;
