import { Box, makeStyles, Typography } from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  routeInfo: {
    padding: "10px 20px",
    "& > *": {
      width: "100%",
      margin: "auto",
      flexWrap: "wrap",
      '@media (max-width:1199px)': {
        paddingLeft: 0
      }
    },
    "& span, p": {
      fontWeight: 600,
    },
  },
  routeLink: {
    textDecoration: "none",
    color: "#242424",
    display: "flex",
    "& > *": {
      margin: "0 8px",
    },
    "&.active": {
      color: "#283e77",
    },
  },
}));

const RouteInfo = ({ paths, searchTag }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.routeInfo}
      display='flex'
      justifyContent='flex-start'
    >
      <Box maxWidth={1200} fontWeight={700} pl={3} display='flex'>
        {searchTag ? (
          <Box
            color='#283e77'
            fontSize={18}
          >{`Search results for "${searchTag}"`}</Box>
        ) : (
          paths?.map((path, index) => (
            <Link
              key={index}
              to={path.url}
              className={classnames(
                classes.routeLink,
                index === paths.length - 1 && "active"
              )}
            >
              <Typography variant='body1'>{path.name}</Typography>
              {index !== paths.length - 1 && (
                <Typography variant='body1'>/</Typography>
              )}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
};
export default RouteInfo;
