import { Box, Typography } from "@material-ui/core";
import classNames from "classnames/bind";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { productUrl } from "../../../helpers/common";
import useStyles from "./styles";
import "../../../index.css";
import { getItemList } from "../../../api/items";

const VariantSizes = ({ product }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productVariants, setProductVariants] = useState([]);

  useEffect(() => {
    if (product.id) {
      getItemList(
        `products?ProductsSearch[variants_of]=${product.id}&ProductsSearch[item_color]=${product.item_color}&sort=id`
      ).then((json) => {
        let data = []
        json?.data?.forEach((item) => {
          const match = data?.find(e => e?.item_size === item?.item_size)

          if (!match) {
            data.push(item)
          }
        })
        setProductVariants(data);

      });
    }
  }, [dispatch, product.id, product.item_color]);

  if (productVariants?.length === 0) {
    return null
  }

  const handleSizeClick = (isActive, variant) => {
    if (!isActive) {
      navigate(productUrl(variant))
    }
  }

  return (
    <Box>
      <Typography variant='h5' className={classes.productName}>
        Sizes
      </Typography>
      <Box className={classes.sizeSec}>
        {productVariants?.map((variant, index) => {
          const isActive = variant?.id === product?.id || variant?.item_size === product?.item_size;

          return (
            <Box
              key={index}
              className={classNames(classes.sizeBox, {
                [classes.activeSize]: isActive,
              })}
              onClick={() => handleSizeClick(isActive, variant)}
            >
              {variant.item_size || 'Free'}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default VariantSizes;
