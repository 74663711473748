import { CssBaseline } from '@material-ui/core';
import React from 'react';
import Footer from './Footer';
import Header from './Header';
import useStyles from './styles';
import CustomSnackbar from '../Common/CustomSnackbar';
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";
import ScrollTopButton from "../ScrollTopButton";

function Layout() {
  const classes = useStyles();
  const snackbarProp = useSelector(state => state.snackbar);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <Outlet />
      <Footer />
      <CustomSnackbar
        open={snackbarProp.open}
        message={snackbarProp.message}
        severity={snackbarProp.severity}
        action={snackbarProp.action}
      />
      <ScrollTopButton />
    </div>
  );
}

export default Layout;
