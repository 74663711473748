
export const server_url = process.env.REACT_APP_API_URL;

export const capitalize = (str) => `${str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()}`

export const sortFn = ((el1, el2, sortKey = 'display_order') => el1[sortKey] > el2[sortKey] ? 1 : -1);

export const imageUrl = function (imageName, prefix = 'products', sizePrefix = '120x120-') {
  if (sizePrefix === 'original') {
    return `${process.env.REACT_APP_DATA_URL}/${prefix}/${imageName}`; // full size image
  } else {
    return `${process.env.REACT_APP_DATA_URL}/${prefix}/${sizePrefix}${imageName}`;
  }
}

export const validEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// it is used to convert the array into string
export const arrayToStr = (array, seperator = ",", defaultValue = "") => {

  return Array.isArray(array) ? (array?.join(seperator) || defaultValue) : defaultValue;

}

export const getErrorMessage = (err) => {
  let error = "Something went wrong.";

  if (err?.response?.data && err?.response?.data[0]?.message) {
    error = err?.response?.data[0]?.message;
  } else if (err?.response?.data?.message) {
    error = err?.response?.data?.message;
  } else {
    error = err?.message;
  }

 return error
};

export const getProductAttributes = (product) => {
  let attributes = []

  if (product?.item_weight) {
    attributes.push(`(Weight: ${product?.item_weight} grams)`)
  }

  if (product?.item_set) {
    attributes.push(`(${product?.item_set})`)
  }

  if (product?.item_color) {
    attributes.push(`(Color: ${product?.item_color})`)
  }

  if (product?.item_size) {
    attributes.push(`(Size: ${product?.item_size})`)
  }

  return attributes.join(' - ')
}


export const truncate = (str, n) =>
  str.length > n ? str.substr(0, n - 1) + '...' : str;

export const convertToSlug = (text) => text.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
// export const convertToSlug = (url_slug) => url_slug;

// to get the product url.
export const productUrl = (product, page = 1) => `/product/${product.id}/${page}/${product.url_slug}`;

export const getCategoryByParentId = (categories, parentId) => categories?.filter(category => parseInt(category.parent_id) === parseInt(parentId));

export const getCategoryLink = (category) => `/category/${category.id}/1/${category.url_slug}`;

export const customerId_local_storage_key = 'shivamjk_customerId';
export const authKey_local_storage_key = 'shivamjk_authToken';
export const cartData_local_storage_key = 'shivamjk_cart_data';
export const userInfo_local_storage_key = 'shivamjk_userInfo';