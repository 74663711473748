import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  dash: {
    height: 5,
    width: 60,
    backgroundColor: '#283e77',
    display: 'flex',
    '&.center': {
      margin: 'auto'
    },
    '&.right': {
      justifyContent: 'flex-end'
    }
  },
}));

const CustomHeading = ({ text, fontSize = 24, align = 'left', ...props }) => {
  const classes = useStyles();

  return (
    <Box {...props}>
      <Box fontSize={fontSize} mb={1} mt={3} textAlign={align}>{text}</Box>
      <Box className={classnames(classes.dash, align === 'center' && 'center')} />
    </Box>
  )
}

export default CustomHeading;
