import React, { useState } from 'react';

import '../style.css';

import { useCallback } from 'react';

import { Razorpay as RazorpayCheckout } from 'razorpay-checkout';

const Razorpay = ({ children, createPayment, onPaymentSuccess, onPaymentFailed }) => {

    const onPaymentFailure = useCallback((response) => {
        onPaymentFailed({
            'reason': response?.error?.description ?? 'Payment Failed. Please try again.'
        });
    }, [onPaymentFailed]);

    const [isLoading, setIsLoading] = useState(false);


    const payNow = useCallback(async () => {

        try {
            setIsLoading(true);
            let orderResponse = await createPayment();
            setIsLoading(false);
            if (orderResponse?.order) {
                if (orderResponse?.order?.order_error_message){
                    onPaymentFailed({
                        'reason': orderResponse?.order?.order_error_message
                    });
                }else{
                    const options = {
                        key: orderResponse?.settings?.api_details?.api_key,
                        amount: orderResponse?.order.total_amount * 100,
                        currency: orderResponse?.order?.currency_code ?? 'INR',
                        order_id: orderResponse?.order?.payment_gateway_order_id,
                        handler: (response) => onPaymentSuccess(orderResponse?.order?.order_id, response)
                    };
                    const rzr = new RazorpayCheckout(options);
                    rzr.on('payment.failed', (errResponse) => onPaymentFailure(errResponse));
                    rzr.open();
                }
            } else {
                throw new Error('Something went wrong. Please try again.');
            }
        } catch (e) {
            onPaymentFailed({
                'reason': e.message
            });
        }

    }, [createPayment, onPaymentFailed, onPaymentFailure, onPaymentSuccess]);

    let button = <button className="razarpayButton payNowButton" >Pay Using Razorpay</button>

    if (children) {
        button = children;
    }

    if (isLoading) {
        button = <img src={require('../images/loader.gif')} width={40} alt="loading" />
    }

    return (
        <div className="payNowButtonContainer" onClick={e => payNow()}>{button}</div>
    )
}

export default Razorpay;
