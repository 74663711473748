import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { convertToSlug, imageUrl } from "../../helpers/common";
import CategoryCard from "../Common/CategoryCard";
import useStyles from "./styles";

export default function HomeCategories() {
  const classes = useStyles();
  const categories = useSelector((state) => state.categories.mainCategories);

  return (
    <div>
      <Box py={6} textAlign='center' className={classes.catogoriesList}>
        <Box fontSize={32} mb={2}>
          Categories
        </Box>
        <Box className={classes.dash} display='flex' margin='auto' />

        <Grid
          container
          spacing={4}
          justifyContent='center'
          className={classes.gridList}
        >
          {categories
            .filter(
              (category) =>
                category.parent_id !== 0 && category.total_sub_categories === 0
            )
            .map((category, index) => (
              <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                <Link
                  to={`/category/${category.id}/1/${convertToSlug(
                    category.name
                  )}`}
                >
                  {" "}
                  <CategoryCard
                    image={imageUrl(category.image, "categories", "430x430-")}
                    title={category.name}
                    width={320}
                    square
                  />
                </Link>
              </Grid>
            ))}
        </Grid>
      </Box>
    </div>
  );
}
