import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../Common/CommonButton";
import PaymentButton from "./PaymentGateways/PaymentButton";
import PaymentGatewayList from "./PaymentGateways/PaymentGatewayList";

const CheckoutDetailsDisplay = ({ customer }) => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.auth.profile);

  const [currentGateway, setCurrentGateway] = useState({});

  const additional_fields = JSON.parse(customer?.all_additional_fields || '{}');

  console.log('additional_fields::', customer);
  
  return (
    <Box>
      <Box style={{ background: "#f1eeee", padding: 15, borderRadius: 15 }}>
        {profile.email && (
          <Box display='flex' my={2} fontSize={16}>
            <Box fontWeight={700} mr={2}>
              Email:
            </Box>
            <Box color='#666'>{profile.email}</Box>
          </Box>
        )}

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            Name:
          </Box>
          <Box color='#666'>{customer.name}</Box>
        </Box>

        {additional_fields?.company_name && (
          <Box display='flex' my={2} fontSize={16}>
            <Box fontWeight={700} mr={2}>
              Company Name:
            </Box>
            <Box color='#666'>{additional_fields?.company_name}</Box>
          </Box>
        )}

        {additional_fields?.gst_aadhaar_number && (
          <Box display='flex' my={2} fontSize={16}>
            <Box fontWeight={700} mr={2}>
              GST No./Adhaar Card No:
            </Box>
            <Box color='#666'>{additional_fields?.gst_aadhaar_number}</Box>
          </Box>
        )}

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            Address:
          </Box>
          <Box color='#666'>{customer.address}</Box>
        </Box>

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            City:
          </Box>
          <Box color='#666'>{customer.city}</Box>
        </Box>

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            Country:
          </Box>
          <Box color='#666'>{customer.country}</Box>
        </Box>

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            Pin Code:
          </Box>
          <Box color='#666'>{customer.pin_code}</Box>
        </Box>

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            Mobile:
          </Box>
          <Box color='#666'>{customer.mobile_number}</Box>
        </Box>

        {additional_fields?.transport_name && (
          <Box display='flex' my={2} fontSize={16}>
            <Box fontWeight={700} mr={2}>
              Transport Name:
            </Box>
            <Box color='#666'>{additional_fields?.transport_name}</Box>
          </Box>
        )}

        {additional_fields?.transport_number && (
          <Box display='flex' my={2} fontSize={16}>
            <Box fontWeight={700} mr={2}>
              Transport Number:
            </Box>
            <Box color='#666'>{additional_fields?.transport_number}</Box>
          </Box>
        )}
      </Box>
      <Box display='flex'>
        <PaymentGatewayList
          currentGateway={currentGateway}
          setCurrentGateway={setCurrentGateway}
        />
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Box mt={3} display='flex'>
          <CommonButton
            rounded
            onClick={() => navigate("/shipping")}
            style={{ width: 150, backgroundColor: "#d5d5d5" }}
          >
            Go Back
          </CommonButton>
        </Box>
        <Box mt={3} display='flex'>
          <PaymentButton
            currentGateway={currentGateway}
            cartStorageKey={"shivamCartProducts"}
          ></PaymentButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutDetailsDisplay;
