import { Avatar, Backdrop, Box, Card, CardActions, CardContent, CardHeader, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import CommonButton from '../Common/CommonButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderDetails } from '../../actions/orders';
import { showErrorSnackbar } from '../../actions/snackbar';

export default function OrderDetailCard({ classes, order: { order_id, order_date, total_amount, coupon_code, total_items } }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentOrder = useSelector(state => state.orders.currOrder);
  const [isReordering, setReorder] = useState(false);

  useEffect(() => {
    if (isReordering && currentOrder.length > 0) {
      const productList = currentOrder.map(order => ({ ...order.product, quantity: order.quantity }));
      localStorage.setItem('shivamCartProducts', JSON.stringify(productList));
      navigate('/cart');
    }
  }, [isReordering, currentOrder, navigate]);

  const viewOrder = () => {
    navigate(`/my-orders/${order_id}`);
  }

  const onReorder = (e) => {
    e.stopPropagation();
    setReorder(true);
    dispatch(getOrderDetails(order_id)).then((ack) => {
      setReorder(false);
      if (!ack) dispatch(showErrorSnackbar('Cannot reorder right now!'));
    });
  }

  return (<>
    {isReordering && (
      <Backdrop open={isReordering} style={{ zIndex: 999 }}>
        <CircularProgress />
      </Backdrop>
    )}
    <Card className={classes.cardRoot} onClick={viewOrder} >
      <CardHeader
        avatar={
          <Avatar src={require('../../assets/images/gift_icon.png')} aria-label="item" className={classes.avatar} />
        }
        title={<Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>{`${total_items} ${total_items > 1 ? 'items' : 'item'}`}</Box>
          <Box>{`Rs. ${total_amount}`}</Box>
        </Box>}
        subheader={`Order placed at ${moment(order_date).format('Do MMM, YYYY')}`}
        classes={{ title: classes.title, subheader: classes.subheader }}
      />
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Box fontWeight={700}>{`Total Price: ${total_amount}`}</Box>
          <Box display="flex" alignItems="center" color="#666">
            <Box fontStyle="italic" mr={1}>{`Coupon: ${coupon_code === '' ? 'Not Applied' : coupon_code}`}</Box>
            <LocalOfferIcon style={{ fontSize: 16 }} />
          </Box>
        </Box>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <CommonButton rounded width={150} onClick={onReorder}>Reorder</CommonButton>
      </CardActions>
    </Card >
  </>);
}

