import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const ShippingDetails = ({ customer, setCustomer, onChange, classes }) => {
  return (
    <>
      <PhoneInput
        value={customer.mobile_number}
        countryCodeEditable={false}
        country={'in'}
        disabled={true}
        masks={{ in: '..........' }}
        preferredCountries={['in']}
        inputStyle={{ width: '100%' }}
        containerStyle={{ marginTop: 32 }}
      />

      <TextField required fullWidth variant="outlined" value={customer.name} name="name" onChange={onChange} label="Your Name" className={classes.shippingField} />
      <TextField required fullWidth variant="outlined" value={customer.address} name="address" onChange={onChange} label="Shipping Address" className={classes.shippingField} />
      <TextField required fullWidth variant="outlined" value={customer.city} name="city" onChange={onChange} label="Shipping City" className={classes.shippingField} />
      <Grid container spacing={3}>
        <Grid item md={4}>
          <CountryDropdown
            value={customer.country}
            onChange={(val) => setCustomer({ ...customer, country: val })}
            className={classes.regions}
          />
        </Grid>

        <Grid item md={4}>
          <RegionDropdown
            country={customer.country}
            value={customer.state || 'Select a State'}
            onChange={(val) => setCustomer({ ...customer, state: val })}
            className={classes.regions}
            blankOptionLabel="Select State"
            defaultOptionLabel="Select State"
          />
        </Grid>

        <Grid item md={4}>
          <TextField
            required
            variant="outlined"
            label="Zip code"
            name="zip_code"
            placeholder="Your zip code"
            value={customer.zip_code}
            inputProps={{ length: 6 }}
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <PhoneInput
        value={customer.additional_phone_number}
        onChange={(val) => setCustomer({ ...customer, additional_phone_number: val })}
        countryCodeEditable={false}
        country={'in'}
        masks={{ in: '..........' }}
        preferredCountries={['in']}
        inputStyle={{ width: '100%' }}
        containerStyle={{ marginTop: 32 }}
        specialLabel="Additional Phone Input"
      />
    </>
  );
}

export default ShippingDetails;
