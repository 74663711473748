import {
  GET_ALL_ORDER_SUCCESS,
  GET_ORDER_DETAILS_SUCCESS,
  PLACE_ORDER_SUCCESS
} from "../constants/actionTypes";

const INITIAL_STATE = {
  shippingDetails: null,
  list: [],
  currOrder: [],
  error: null
}

export default function orders(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PLACE_ORDER_SUCCESS:
      return { ...state, shippingDetails: action.payload.data.customer};
    case GET_ALL_ORDER_SUCCESS:
      return { ...state, list: action.payload.data.filter(order => order.order_status === 'Success') }
    case GET_ORDER_DETAILS_SUCCESS:
      return { ...state, currOrder: action.payload.data }
    default:
      return state;
  }
}
