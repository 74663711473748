import { Box, Button } from '@material-ui/core';
import React from 'react';

const ShippingDetailsDisplay = ({
  customer: {
    mobile_number,
    name,
    address,
    city,
    country,
    zip_code,
    additional_phone_number
  },
  setEdit
}) => {
  return (
    <Box>
      <Box display="flex" my={2} fontSize={16}>
        <Box fontWeight={700} mr={2}>Mobile Number</Box>
        <Box color="#666">{mobile_number}</Box>
      </Box>

      <Box display="flex" my={2} fontSize={16}>
        <Box fontWeight={700} mr={2}>Name</Box>
        <Box color="#666">{name}</Box>
      </Box>

      <Box display="flex" my={2} fontSize={16}>
        <Box fontWeight={700} mr={2}>Address</Box>
        <Box color="#666">{address}</Box>
      </Box>

      <Box display="flex" my={2} fontSize={16}>
        <Box fontWeight={700} mr={2}>City</Box>
        <Box color="#666">{city}</Box>
      </Box>

      <Box display="flex" my={2} fontSize={16}>
        <Box fontWeight={700} mr={2}>Country</Box>
        <Box color="#666">{country}</Box>
      </Box>

      <Box display="flex" my={2} fontSize={16}>
        <Box fontWeight={700} mr={2}>Zip Code</Box>
        <Box color="#666">{zip_code}</Box>
      </Box>

      <Box display="flex" my={2} fontSize={16}>
        <Box fontWeight={700} mr={2}>Additional Mobile Number</Box>
        <Box color="#666">{additional_phone_number}</Box>
      </Box>

      <Box>
        <Button variant="contained" onClick={() => setEdit(true)}>Edit Shipping Details</Button>
      </Box>
    </Box>
  )
}

export default ShippingDetailsDisplay;
