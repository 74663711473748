import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: "10px 3px",
    padding: '3px 15px',
    lineHeight: 1.2,
    fontSize: 16,
    fontWeight: 700,
    height: 'auto',
    '& span': {
      whiteSpace: 'normal',
      textAlign: 'center'
    },
    [theme.breakpoints.down("xs")]: {
      width: "98%",
    },
  },
  similarProductsSec: {
    marginTop: 20
  },
  link: { textDecoration: "none", color: "inherit" },
  commonButton: {
    width: 195,
    height: 53,
    borderRadius: 38,
    background: "#283e77",
    color: "#fff",
    "@media (max-width: 380px)": {
      width: 150,
      fontSize: 13,
    },
  },
  mainImgBox: {
    width: 416,
    height: 480,
    "@media (max-width: 450px)": {
      width: "100%",
      height: "auto",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  ratingIcon: {
    color: "#283e77",
  },
  skeleton: {
    marginBottom: 12,
  },
  colorPrimary: {
    width: 120,
    background: "#283e77",
  },
  writeReview: {
    paddingLeft: 20,
  },
  ratingContainer: {
    borderRadius: 8,
    padding: 20,
    background: "#fff",
    border: "1px solid #aaa",
  },
  reviewForm: {
    marginTop: 24,
    marginRight: 24,
    "& > *": {
      marginTop: 16,
    },
    marginBottom: 15,
  },
  zipInput: {
    "& > .MuiInputBase-root": {
      borderRadius: 30,
      backgroundColor: "#eee",
      fontFamily: "Product Sans",
      fontWeight: 700,
    },
  },
  gridList: {
    marginTop: 20,
    flexWrap: "nowrap",
  },
  gridListTile: {
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    border: "2px solid transparent !important",
    marginLeft: 4,
    marginBottom: 4,
    "&:hover": {
      border: "2px solid #283e77 !important",
    },
  },
  additional_fields_text: {
    fontSize: 18,
    marginTop: 8,
  },
  colorSec: {
    display: "flex",
    marginBottom: 15,
    flexWrap: "wrap",
    marginTop: 10,
    gap: 10,
  },
  colorBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #aaa",
    textAlign: "center",
    borderRadius: "50%",
    width: 60,
    height: 60,
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "& > img": {
      height: "52px !important",
      width: "52px !important",
    },
    "&:hover": {
      background: "#283e77",
      transition: "all 0.3s ease-in-out",
      border: "2px solid #283e77 !important",
    },
  },
  sizeSec: {
    display: "flex",
    flexWrap: "wrap",
  },
  sizeBox: {
    border: "1px solid #aaa",
    marginTop: 10,
    marginRight: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 15,
    minWidth: 90,
    height: 45,
    borderRadius: 10,
    padding: '8px 15px',
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      background: "#283e77",
      color: "#fff",
      transition: "all 0.3s ease-in-out",
    },
  },
  setSec: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 15,
    marginBottom: 15,
    gap: 10,
  },
  setBox: {
    border: "1px solid #aaa",
    padding: "10px 7px",
    borderRadius: 8,
    cursor: "pointer",
    minWidth: 110,
    "&:hover": {
      background: "#283e77",
      color: "#fff",
      transition: "all 0.3s ease-in-out",
    },
  },
  item_set: {
    borderBottom: "1px solid #aaa",
    paddingBottom: 7,
    marginBottom: 7,
    fontSize: 16,
  },
  price: {
    fontSize: 18,
  },
  activeColor: {
    border: "2px solid #283e77 !important",
    background: "#283e77",
  },
  activeSize: {
    border: "2px solid #283e77 !important",
  },
}));

export default useStyles;
