import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core'
import OrderDetailCard from './OrderDetailCard';

import useStyles from './styles';
import { useDispatch } from 'react-redux';
import { getAllOrder } from '../../actions/orders';

export default function OrderHistory(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [orders , setOrders] = useState([]);


  useEffect(() => {
    setLoading(true);
    dispatch(getAllOrder()).then((response) => {
      setOrders(response.payload.data);
      setLoading(false);
    });
  }, [dispatch]);

  return (
    <Box p={3}>
      <Box m="auto" maxWidth={1200} minHeight={300}>
        <Box fontSize={22} fontWeight={600} color="#666">Order History</Box>

        {loading ? <CircularProgress className={classes.loader} /> : <Grid container spacing={2} justifyContent="center">
        {orders?.length === 0 ? <Box fontSize={20} color="#666" style={{textAlign: 'center', marginTop: 100}}>No Orders Found!</Box>: null}
          {orders?.map(order => <Grid item key={order.id} sm={4} style={{ margin: '20px 0' }}>
            <OrderDetailCard classes={classes} order={order} />
          </Grid>
          )}
        </Grid>}
      </Box>
    </Box>
  )
};
