import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({ 
"@media (max-width: 480px)" :{
  input: {
    maxWidth: '100%',
    flexBasis: '100%',
    marginHorizontal: 10
  },
}
}));

export default useStyles;
