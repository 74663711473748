import { Box, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../Common/CommonButton";
import ReCAPTCHA from "react-google-recaptcha";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";
import { contactUs } from "../../api/contacts";
import MetaTags from "../MetaTags";

export default function ConactUs(props) {
  const customer = useSelector((state) => state.auth.profile);
  const [email, setEmail] = useState(customer.email ?? "");
  const [name, setName] = useState(customer.name ?? "");
  const dispatch = useDispatch();
  const [subject, setSubject] = useState("");
  const [feedback, setFeedback] = useState("");
  const content = useSelector((state) => state.contents.homepage);
  const frontendData = useSelector((state) => state.contents.frontendData);
  const [loading, setLoading] = useState(false);
  const recaptchaRef = React.createRef();

  const google_recaptcha_site_key = frontendData?.find(
    (data) => data.key === "google_recaptcha_site_key"
  )?.value;

  const onSubmit = (e) => {
    e.preventDefault();

    const captcha_value = recaptchaRef?.current?.getValue();
    if (!captcha_value) {
      dispatch(showErrorSnackbar("Please verify captcha!"));
      return false;
    }

    setLoading(true);

    contactUs({
      name,
      email_address: email,
      subject,
      message: feedback,
      captcha_value,
    })
      .then((json) => {
        setLoading(false);
        setName("");
        setEmail("");
        setFeedback("");
        setSubject("");
        dispatch(
          showSuccessSnackbar(
            "Your query has been submitted successfully! We will contact you shortly!"
          )
        );
      })
      .catch((error) => {
        console.log("error::", error);
        setLoading(false);
        dispatch(showErrorSnackbar("something went wrong!"));
      });
  };

  return (
    <>
      <Box maxWidth={700} m='48px auto' fontFamily='Product Sans'>
        <Box
          color='#283e77'
          textAlign='center'
          fontSize={36}
          mb={2}
          fontWeight={700}
        >
          Contact Us
        </Box>
        <Box
          color='#666'
          textAlign='center'
          dangerouslySetInnerHTML={{
            __html: content?.find(
              (data) => data.name === "contact_us_under_heading_text"
            )?.content,
          }}
        />
        <Box p={3}>
          <form onSubmit={onSubmit}>
            <TextField
              type='name'
              label='Name'
              placeholder='Your Name'
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
              style={{ marginBottom: 20 }}
              variant='outlined'
              required
            />
            <TextField
              type='email'
              label='Email'
              placeholder='Your Email'
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              style={{ marginBottom: 20 }}
              variant='outlined'
              required
            />

            <TextField
              type='subject'
              label='Subject'
              placeholder='Your Subject'
              value={subject}
              fullWidth
              onChange={(e) => setSubject(e.target.value)}
              style={{ marginBottom: 20 }}
              variant='outlined'
              required
            />

            <TextField
              variant='outlined'
              label='Your feedback'
              placeholder='Enter your feedback'
              multiline={true}
              fullWidth
              rows={4}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              required
            />

            <Box
              mt={3}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              {google_recaptcha_site_key ? (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={google_recaptcha_site_key}
                  className='mx-auto d-inline-block'
                />
              ) : null}
            </Box>
            <Box display='flex' justifyContent='center' mt={3}>
              <CommonButton rounded type='submit' loading={loading} width={250}>
                Submit Feedback
              </CommonButton>
            </Box>
          </form>
        </Box>
      </Box>
      <MetaTags
        title='Contact Us'
        description='You can reach us for any query or inquiry'
      />
    </>
  );
}
