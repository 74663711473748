import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useStyles from "./styles";
import { getSimilarProducts } from "../../../api/items";
import { Box, Grid, Typography } from "@material-ui/core";
import { convertToSlug, imageUrl } from "../../../helpers/common";
import { Link } from "react-router-dom";
import Products from "../../Common/Products";

export default function SimilarProducts({ product }) {
  const [similarProducts, setSimilarProducts] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && product.id) {
      getSimilarProducts(product.id)
        .then(function (response) {
          setSimilarProducts(response?.data);
        })
        .catch(function (error) {
          console.error("error::", error);
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [product]);

  const responsive = {
    default: {
      breakpoint: { max: 4000, min: 991 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const windowScroll = () => {
    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });
  };

  const ProductBox = ({ item }) => {
    return (
      <Box p={2} onClick={windowScroll}>
        {product.main_image && (
          <Link
            to={`/product/${item?.id}/1/${convertToSlug(item?.name)}`}
            className={classes.link}
          >
            <Products
              image={imageUrl(
                item?.main_image,
                "products/" + item?.id,
                "600x600-"
              )}
              title={item?.name}
              price={item?.mrp}
              discountedPrice={item?.selling_price}
            />
          </Link>
        )}
      </Box>
    );
  };


  if (similarProducts?.length === 0) {
    return null;
  }

  return (
    <div className='container'>
      <div className={classes.similarProductsSec}>
        <Typography variant='h5' style={{fontWeight: 700}}>
          Similar Products
        </Typography>

        {similarProducts?.length > 3 ? (
          <Carousel
            draggable={true}
            keyBoardControl={true}
            responsive={responsive}
            infinite={true}
            partialVisible={"false"}
            autoPlay
            autoPlaySpeed={3000}
            arrows={true}
          >
            {similarProducts?.map((item) => (
              <ProductBox item={item} />
            ))}
          </Carousel>
        ) : (
          <Grid container>
            {similarProducts?.map((item) => (
              <Grid item key={item?.id} md={4} sm={6}>
                <ProductBox item={item} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
}
