import React, { useState, useEffect, useCallback } from "react";
import { fetchProductsByConditions } from "../../actions/products";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import ListingBanner from "../ProductListing/ListingCarousel";
import useStyles from "./styles";
import CustomHeading from "../Common/CustomHeadings";
import CategoryList from "../Common/CategoryList";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { imageUrl } from "../../helpers/common";
import Products from "../Common/Products";
import RouteInfo from "../Common/RouteInfo";
import { getCategory } from "../../actions/categories";

export default function SearchResult(props) {
  const classes = useStyles();
  // const [page, setpage] = useState(1);
  const [productList, setList] = useState([]);
  const { keyword } = useParams();
  const [loading, setLoading] = useState(true);
  const categories = useSelector((state) => state.categories.mainCategories);
  const dispatch = useDispatch();

  const asyncCallsOnLoad = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchProductsByConditions(
        "ProductsSearch[keyword]=" + keyword
      );
      setList(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      console.log(err?.response);
    }
  }, [keyword]);

  useEffect(() => {
    asyncCallsOnLoad();
  }, [asyncCallsOnLoad]);

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  return (
    <Box>
      <ListingBanner />
      <RouteInfo searchTag={keyword} />
      <Grid container style={{ maxWidth: 1170, margin: "auto", padding: 15 }}>
        <Grid item md={3} xs={12} className={classes.categoryList}>
          <CustomHeading ml={3} text="Categories" />
          <CategoryList categories={categories} />
        </Grid>
        <Grid item md={9} xs={12} className={classes.mainGrid}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box fontSize={36} m={3} flex={1}>
              Search Result
            </Box>
          </Box>

          <>
            <Grid
              container
              justifyContent={productList.length === 0 ? "center" : "flex-start"}
              alignItems="center"
              spacing={3}
              style={{ justifyContent: "center" }}
            >
              {loading ? (
                <CircularProgress />
              ) : productList.length > 0 ? (
                productList.map((product) => (
                  <Grid key={product.id} item xs={12} sm={4} md={4}>
                    <Link
                      to={`/product/${product.id}/${parseInt(
                        1,
                        10
                      )}/${product.url_slug}`}
                      className={classes.link}
                    >
                      <Products
                        image={imageUrl(
                          product.main_image,
                          "products/" + product.id,
                          "600x600-"
                        )}
                        title={product.name}
                        price={product.mrp}
                        discountedPrice={product.selling_price}
                      />
                    </Link>
                  </Grid>
                ))
              ) : (
                <Box
                  mt={8}
                  style={{ marginBottom: 80 }}
                  fontSize={32}
                  fontWeight={700}
                  color="#999"
                  textAlign="center"
                >
                  No products found
                </Box>
              )}
            </Grid>
          </>
        </Grid>
      </Grid>
    </Box>
  );
}
