import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getBanners } from "../../actions/banners";
import { getCategory } from "../../actions/categories";
import { getProductList, getProducts } from "../../actions/products";
import { imageUrl, sortFn } from "../../helpers/common";
import CategoryList from "../Common/CategoryList";
import CustomHeading from "../Common/CustomHeadings";
import Products from "../Common/Products";
import RouteInfo from "../Common/RouteInfo";
import ListingBanner from "./ListingCarousel";
import SingleProduct from "./SingleProduct";
import useStyles from "./styles";

// const valuetext = (value) => `Rs.${value}`;

const ProductListing = () => {

  const classes = useStyles();
  const { categoryId, currentPage, categoryName, productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setpage] = useState(1);
  const [routeArray, setRouteArr] = useState([]);
  // const [marks, setMarks] = useState([
  //   { value: 0, label: valuetext(0) },
  //   { value: 20000, label: valuetext(20000) },
  // ]);
  const [productList, setList] = useState([]);
  // const [priceRange, setPriceRange] = useState([]);
  const [activeProduct, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [sortKey, setSortKey] = useState("display_order");
  const searchTag = new URLSearchParams(window.location.search).get(
    "search_tag"
  );
  const products = useSelector((state) => state.products.list);
  const sellingProducts = useSelector((state) => state.products.selling);
  const offeredProducts = useSelector((state) => state.products.offerProducts);
  const featuredProducts = useSelector((state) => state.products.featured);
  const totalItems = useSelector((state) => state.products.itemCount);
  const lastPage = useSelector((state) => state.products.pageCount);
  const categories = useSelector((state) => state.categories.mainCategories);

  useEffect(() => {
    if (categories.length === 0) return;
    const initialRouteInfo = { name: "Home", url: "/" };

    if (categoryId && categoryName) {
      const categoryHeaderName = categories.find(
        (category) => category.id === parseInt(categoryId, 10)
      )?.name;
      setRouteArr([
        initialRouteInfo,
        {
          name: categoryHeaderName,
          url: `/category/${categoryId}/1/${categoryName}`,
        },
      ]);
    } else if (productId) {
      const productList = [
        ...products,
        ...sellingProducts,
        ...offeredProducts,
        ...featuredProducts,
      ];
      const currentProduct = productList.find(
        (product) => product.id === parseInt(productId, 10)
      );
      if (currentProduct) {
        setProduct(currentProduct);
      }
      const category = categories.find(
        (category) => category.id === parseInt(currentProduct?.category_id, 10)
      );
      setRouteArr([
        initialRouteInfo,
        {
          name: category?.name,
          url: `/category/${category?.id}/1/${category?.name}`,
        },
        { name: currentProduct?.name, 
          url: `/product/${currentProduct?.id}/1/${currentProduct?.url_slug}` },
      ]);
    } else if (categoryId && !categoryName) {
      const featureName = {
        "hot-products": "Hot Products",
        is_offer: "Products on offer",
        is_featured: "Featured Products",
      };
      setRouteArr([
        initialRouteInfo,
        { name: featureName[categoryId], url: `/products/${categoryId}/1` },
      ]);
    }
  }, [
    categoryId,
    categories,
    productId,
    products,
    sellingProducts,
    offeredProducts,
    featuredProducts,
    categoryName,
  ]);

  useEffect(() => {
    dispatch(getBanners("Listing Banners")).then(() => {
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    });
    dispatch(getCategory());
  }, [dispatch]);

  useEffect(() => {
    setpage(parseInt(currentPage, 10));
  }, [currentPage]);

  useEffect(() => {
    setLoading(true);

    if (productId) {
      dispatch(getProducts({ id: productId }));
      dispatch(getProducts({ similar_of: productId })).then(() =>
        setLoading(false)
      );
      return;
    }

    if (categoryId && !categoryName) {
      const optionName = { "hot-products": "Hot" };
      dispatch(
        getProducts(
          {
            category: 1,
            product_labels: optionName[categoryId],
          },
          15,
          page
        )
      ).then(() => setLoading(false));
    } else if (searchTag) {
      dispatch(getProducts({ search_tags: searchTag }, 15, page)).then(() =>
        setLoading(false)
      );
    } else if (categoryId && categoryName) {
      dispatch(
        getProductList(`ProductsSearch[category_id]=${categoryId}&group_by=item_color`)
      ).then(() => setLoading(false));
    }
  }, [
    dispatch,
    page,
    categoryId,
    searchTag,
    productId,
    categoryName,
  ]);

  useEffect(() => {
    switch (categoryId) {
      case "hot-products":
        return setList(sellingProducts);
      default:
        return setList(products);
    }
  }, [
    categoryId,
    setList,
    products,
    sellingProducts,
    offeredProducts,
    featuredProducts,
  ]);


  const heading = () => {
    if (categoryId)
      return categories.find(
        (category) => category.id === parseInt(categoryId, 10)
      )?.name;
    else if (categoryId === "is_hot") return "Selling Products";
    else if (categoryId === "is_offer") return "Product offers";
    else if (categoryId === "is_feature") return "Featured Products";
  };

  const handlePageChange = (_, v) => {
    setpage(v);
    navigate(
      categoryName
        ? `/category/${categoryId}/${v}/${categoryName}`
        : `/category/${categoryId}/${v}`
    );
  };

  return (
    <Box mb={3}>
      <ListingBanner />
      <RouteInfo searchTag={searchTag} paths={routeArray} />
      <Grid container style={{ maxWidth: 1170, margin: "auto", padding: 15 }}>
        <Grid item md={3} xs={12} className={classes.categoryList}>
          <CustomHeading text="Categories" />
          <CategoryList
            categories={categories}
            activeCategoryId={categoryId || activeProduct?.category_id}
          />
          {/* {!productId && (
            <>
              <CustomHeading ml={3} text="Filter By Price" />
              <Box className={classes.sliderContainer}>
                <CustomSlider
                  min={0}
                  max={20000}
                  step={1}
                  value={priceRange}
                  onChange={(_, v) => v[0] !== v[1] && setPriceRange(v)}
                  aria-labelledby="price-filter-slider"
                  getAriaValueText={valuetext}
                  marks={marks}
                />
              </Box>
            </>
          )} */}
        </Grid>
        <Grid item md={9} xs={12} className={classes.mainGrid}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box fontSize={36} m={3} flex={1}>
              {heading()}
            </Box>
            {productList.length !== 0 && !activeProduct && (
              <>
                <Box mr={1} fontSize={18}>
                  Sort By
                </Box>
                <FormControl variant="outlined" margin="dense">
                  <Select
                    id="sort-select"
                    value={sortKey}
                    onChange={(e) => setSortKey(e.target.value)}
                  >
                    <MenuItem value="display_order">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"created_at"}>Newest</MenuItem>
                    <MenuItem value={"selling_price"}>Price</MenuItem>
                    <MenuItem value={"name"}>Name</MenuItem>
                    <MenuItem value={"is_hot"}>Popularity</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </Box>

          {!productId ? (
            <>
              <Grid
                container
                justifyContent={productList.length === 0 ? "center" : "flex-start"}
                alignItems="center"
                spacing={3}
                style={{ justifyContent: 'center' }}
              >
                {loading ? (
                  <CircularProgress />
                ) : productList.length > 0 ? (
                  productList
                    .sort((el1, el2) => sortFn(el1, el2, sortKey))
                    .map((product) => (
                      <Grid key={product.id} item xs={12} sm={4} md={4} >
                        <Link
                          to={`/product/${product.id}/${parseInt(
                            page,
                            10
                          )}/${product.url_slug}`}
                          className={classes.link}
                        >
                          <Products
                            image={imageUrl(
                              product.main_image,
                              "products/" + product.id,
                              "600x600-"
                            )}
                            title={product.name}
                            price={product.mrp}
                            discountedPrice={product.selling_price}
                          />
                        </Link>
                      </Grid>
                    ))
                ) : (
                  <Box
                    mt={8}
                    style={{ marginBottom: 80 }}
                    fontSize={32}
                    fontWeight={700}
                    color="#999"
                    textAlign="center"
                  >
                    No products found
                  </Box>
                )}
              </Grid>

              <Box className={classes.bottomBar}>
                <Box>
                  {productList.length} items of total {totalItems} items
                </Box>
                {currentPage && (
                  <Pagination
                    classes={{ ul: classes.pagination }}
                    count={parseInt(lastPage, 10)}
                    page={page}
                    defaultPage={1}
                    onChange={handlePageChange}
                  />
                )}
              </Box>
            </>
          ) : (
            activeProduct && <SingleProduct product={activeProduct} />
          )}
        </Grid>
      </Grid>

      {/* {similarProducts.length !== 0 && (
        <Box pt={3} pb={8} mt={5} className={classes.bottomCarousel}>
          <CustomHeading
            text={productId ? "Similar Products" : "Featured Products"}
            align="center"
            my={3}
          />
          <FeaturedProductCarousel productId={productId} />
        </Box>
      )} */}
    </Box>
  );
};

export default ProductListing;
