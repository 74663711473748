import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cartItemContainer: {
    border: '1px solid #666',
    borderRadius: 6,
    alignItems: 'center',
    padding: 24,
    justifyContent: 'space-between',
    "@media (max-width: 600px)": {
      flexDirection: 'column',
      padding: 15,
    }
  },
  left: {
    "@media (max-width: 600px)": {
      marginBottom: 20
    }
  },
  images: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    border: '1px solid #aaa',
    borderRadius: 6
  },
  products: {
    padding: 40,
    "@media (max-width: 600px)": {
      padding: 20,
    },
    '& > *': {
      maxWidth: 1200,
      margin: 'auto'
    }
  },
  priceText: {
    fontSize: 18,
    "@media (max-width: 767px)": {
      fontSize: 16,
    },
  },
  placeOrder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "@media (max-width: 480px)": {
      display: 'none'
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    fontSize: 16,
    '&:hover': {
      color: 'breen'
    }
  },
  itemImg: {
    width: 120,
    height: 120,
    cursor: 'pointer',
    "@media (max-width: 480px)":{
      width: 100,
      height: 100,
    },
  },
  itemText: {
    fontSize: 24,
    "@media (max-width: 767px)":{
      fontSize: 20,
    }
  },
  qtyInput:{
    width: 195,
    "@media (max-width: 767px)":{
      width: 130,
    },
    "@media (max-width: 480px)": {
      width: 100,
    },
  }
}));

export default useStyles;
