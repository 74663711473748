import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import useStyles from "../styles";
import FeaturedProductCarousel from "./FeaturedProductsCarousel";

export default function FeaturedProducts() {
  const classes = useStyles();
  const featuredProductSize = useSelector(
    (state) => state.products.featured
  ).length;

  return (
    <div>
      {featuredProductSize !== 0 && (
        <Box textAlign='center' p={5} className={classes.featuredCarousel}>
          <Box fontSize={32} mb={1}>
            Featured Products
          </Box>
          <Box className={classes.dash} display='flex' margin='auto' mb={3} />
          <FeaturedProductCarousel />
        </Box>
      )}
    </div>
  );
}
