import React from "react";
import useStyles from "./styles";
import { Box, Divider, Grid } from "@material-ui/core";
import { getProductAttributes, imageUrl } from "../../helpers/common";
import { useSelector } from "react-redux";

export default function ShippingProductDisplay({ deliveryCharges = 0 }) {
  const classes = useStyles();

  const products = useSelector((state) => state.cart.data);

  // const getCourierCharge = () => {
  //   return products?.reduce(
  //     (acc, product) => acc + product.delivery_charges * product.qty,
  //     0
  //   );
  // };

  const getTotal = () => {
    return products?.reduce(
      (acc, product) => acc + product.selling_price * product.qty,
      0
    );
  };

  return (
    <Grid item md={6} className={classes.items}>
      {products.map((product) => (
        <Box key={product.id} my={2} display="flex" alignItems="center">
          <Box
            width={80}
            height={80}
            borderRadius={5}
            border="1px solid transparent"
          >
            <img
              className={classes.images}
              src={imageUrl(product.main_image, "products/" + product.id)}
              alt="cart-item"
            />
          </Box>

          <Box flex={1} ml={2}>
            <Box fontSize={20} fontWeight={700}>
              {product.name}
            </Box>
            <Box mr={2}>{getProductAttributes(product)}</Box>
            <Box fontSize={18} display="flex" color="#666">
              {product.mrp !== product.selling_price && product.mrp !== 0 ? (
                <Box mr={1} style={{ textDecoration: "line-through" }}>
                  &#x20b9;{product.mrp}
                </Box>
              ) : null}
              <Box>
                &#x20b9;{product.selling_price} x {product.qty}
              </Box>
            </Box>
          </Box>

          <Box fontSize={24} mx={3} fontWeight={700}>
            &#x20b9;{product.selling_price * product.qty}
          </Box>
        </Box>
      ))}

      {/* <Divider /> */}

      {/* <Box my={2} display="flex" alignItems="center">
              <TextField
                variant="outlined"
                label="Coupon Code"
                placeholder="Enter coupon code"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                style={{ marginRight: 16 }}
              />
              <CommonButton loading={false} variant="contained" style={{ height: 40 }} color="primary">Apply</CommonButton>
            </Box> */}

      <Divider />

      <Box display="flex" my={2} justifyContent="space-between" fontSize={18}>
        <Box color="#666">Subtotal</Box>
        <Box fontWeight={700}>&#x20b9;{getTotal()}</Box>
      </Box>

      <Box display="flex" my={2} justifyContent="space-between" fontSize={17}>
        <Box color="#666">Total Discount</Box>
        <Box fontWeight={500}>&#x20b9; 0</Box>
      </Box>

      <Box display="flex" my={2} justifyContent="space-between" fontSize={17}>
        <Box color="#666">Courier Charges</Box>
        <Box fontWeight={500}>&#x20b9; {deliveryCharges} </Box>
      </Box>

      <Divider />

      <Box display="flex" mt={2} justifyContent="space-between" fontSize={20}>
        <Box fontWeight={700}>Total Amount</Box>
        <Box fontWeight={700} color="#283e77">
          &#x20b9; {getTotal() + deliveryCharges}
        </Box>
      </Box>

      <Box textAlign="right">(Incl. 18% GST)</Box>
    </Grid>
  );
}
