import React from 'react';
import { Box, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { convertToSlug, imageUrl, sortFn } from "../../helpers/common";
import useStyles from "./styles";
import Products from '../Common/Products';
import CommonButton from '../Common/CommonButton';


export default function HotProducts() {
  const classes = useStyles();
  const hotProducts = useSelector((state) => state.products.selling);


  return (
    <div>{hotProducts.length !== 0 && (
      <Box textAlign="center" className={classes.hotProductsBox}>
        <Box fontSize={32} mb={1}>
          Hot Products
        </Box>
        <Box className={classes.dash} display="flex" margin="auto" mb={3} />

        <Grid container spacing={4} className={classes.gridList}>
          {hotProducts.sort(sortFn).map((product) => (
            <Grid item key={product.id} md={3} style={{ margin: "auto" }}>
              <Box display="flex" justifyContent="center">
                {product.main_image && (
                  <Link
                    to={`/product/${product.id}/1/${convertToSlug(
                      product.name
                    )}`}
                    className={classes.link}
                  >
                    <Products
                      image={imageUrl(
                        product.main_image,
                        "products/" + product.id,
                        "600x600-"
                      )}
                      title={product.name}
                      price={product.mrp}
                      discountedPrice={product.selling_price}
                    />
                  </Link>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
        <CommonButton rounded style={{ marginTop: 25 }}>
          <Link to="/products/hot-products/1" className={classes.link}>
            View All Hot Products
          </Link>
        </CommonButton>
      </Box>
    )}</div>
  )
}
