import React, { useState } from 'react';
import { Box, Divider, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Link, useNavigate } from 'react-router-dom';
import { getProductAttributes, imageUrl } from '../../helpers/common';
import CommonButton from '../Common/CommonButton';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { updateCartData } from "../../cart";
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { updateCart } from '../../actions/cart';
import { showErrorSnackbar } from '../../actions/snackbar';



const Cart = () => {

  const navigate = useNavigate();
  const classes = useStyles();
  const [remark, setRemark] = useState('');
  const products = useSelector(state => state.cart.data);
  const dispatch = useDispatch();

  const checkoutOrders = () => {
    navigate('/shipping', { state: {remark: remark} });
  }
  const getTotal = () => products?.reduce((acc, product) => acc + parseInt(product.selling_price, 10) * parseInt((product.qty ? product.qty : 1 ), 10), 0);

  const setQuantity = (e, product) => {
    const newCartData = updateCartData(products, product, e.target.value);
    dispatch(updateCart(newCartData))
  }

  const removeItem = (product) => {
    const newCartData = updateCartData(products, product, 0);
    dispatch(updateCart(newCartData));

    dispatch(showErrorSnackbar(`Product "${product.name}" removed from the cart`, null));
  }

  return (
    <Box className={classes.products} p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box flex={1} fontSize={26} fontWeight={700}>Your Cart</Box>
        {products.length > 0 && (<>
          <Box className={classes.placeOrder}>
            <Box mr={2} fontFamily={'Product Sans'}>
              <Box fontSize={16} color="#666">Subtotal</Box>
              {/* <Box fontSize={18} >&#x20b9;{getTotal()}</Box> */}
            </Box>
            <CommonButton rounded width={180} loading={false} onClick={checkoutOrders}>Place Order</CommonButton>
          </Box>
        </>)}
      </Box>

      <Box mt={3}>
        {products.length > 0 ? products.map(product =>
          <Box
            key={product.id}
            my={3}
            p={3}
            display="flex"
            className={classes.cartItemContainer}
          >
           <Box display="flex" alignItems="center" className={classes.left}>
              <Box className={classes.itemImg} borderRadius={5} border="1px solid transparent" onClick={() => navigate(`/product/${product.id}/1/${product.url_slug}`)}>
                <img className={classes.images} src={imageUrl(product.main_image, 'products/' + product.id, "120x120-")} alt="cart-item" />
              </Box>
  
              <Box flex={1} ml={2}>
                <Box className={classes.itemText} fontSize={24} fontWeight={700} onClick={() => navigate(`/product/${product.id}/1/${product.url_slug}`)}>
                  {product.name}
                </Box>

                <Box>{getProductAttributes(product)}</Box>
                <Box className={classes.priceText} display="flex" color="#666">
                  <Box mr={1}>Price</Box>
                  <Box mr={1} style={{ textDecoration: 'line-through' }}>&#x20b9;{product.mrp}</Box>
                  <Box>&#x20b9;{product.selling_price}</Box>
                </Box>
              </Box>

           </Box> 
            <Box display="flex" alignItems="center">
              <Box>
                <TextField
                  label="Quantity"
                  variant="outlined"
                  value={product.qty ?  product.qty : 1}
                  type="number"
                  onChange={e => setQuantity(e, product)}
                  className={classes.qtyInput}
                  style={{ marginRight: 12}}
                  // classes={{ root: classes.zipInput }}
                  inputProps={{ min: 1, max: parseInt(product.sku ?? 0, 10) }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Qty:</InputAdornment>,
                  }}
                />
              </Box>
            <Box fontSize={24} mx={3} color="#666">&#x20b9;{product.selling_price * (product.qty ? product.qty : 1) }</Box>

            {/* <IconButton onClick={() => removeItem(product.id)}> */}
            <IconButton onClick={() => removeItem(product)} >
              <HighlightOffOutlinedIcon />
             </IconButton>
            </Box>
          </Box>) : (
          <Box color="#666" my={3} fontSize={24} textAlign="center">
            Your cart is empty
          </Box>
        )}
      </Box>
      <Divider fullWidth />
      {products.length > 0 && <><Box display="flex" pt={4} px={2} color="#666" justifyContent="space-between">
        <Box fontWeight={700} fontSize={24}>
          Subtotal
        </Box>
        <Box fontWeight={700} fontSize={24}>
          &#x20b9;{getTotal()}
        </Box>
      </Box>
        <Box px={2} pb={2} >
          Shipping & taxes calculated at checkout
        </Box>

        <Box px={2} pb={3}>
          <TextField
            label="Your Remark"
            variant="outlined"
            value={remark}
            fullWidth
            placeholder="Anything you want to add along with delivery ?"
            onChange={(e) => setRemark(e.target.value)}
          />
        </Box>

        <Box>
          <CommonButton rounded loading={false} style={{ width: '100%', fontSize: 20, borderRadius: 28 }} startIcon={<ShoppingCartIcon />} onClick={checkoutOrders}>
            Place Order
          </CommonButton>
        </Box>
      </>}

      <Box textAlign="center" p={2}>
        <Link to="/" className={classes.link}>Continue Shopping</Link>
      </Box>
    </Box >
  )
}

export default Cart;
