import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cartButton: {
    backgroundColor: '#283e77',
    borderRadius: 2,
    height: 50,
    width: 50,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#FAA499'
    }
  },
  productBoxTitleContainer: {
    width: 'calc(100% - 55px)',
  },
  productBoxTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    '&:hover': {
      transition: 'all ease-in-out 0.5s',
      transform: 'scale(1.05)'
    }
  }
}));

export default useStyles;
