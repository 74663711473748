import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  commonButton: {
    width: 195,
    height: 53,
    borderRadius: 38,
    background: '#283e77',
    color: '#fff',
    "@media (max-width: 380px)":{
      width: 150,
      fontSize: 13
    }
  }
}));

export default useStyles;
