import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPage } from "../../api/pages";
import MetaTags from "../MetaTags";

import NotFound from "../NotFoundPage";

const CmsPage = () => {
  const { page } = useParams();
  const [details, setDetails] = useState({ content: "", title: "" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (page) {
      setLoading(true);
      getPage(page)
        .then((json) => setDetails(json.data?.[0]))
        .finally(() => setLoading(false));
    }
  }, [page]);

  if (loading) {
    return (
      <Box m='48px auto'>
        <CircularProgress />
      </Box>
    );
  }

  return details ? (
    <Box maxWidth={1200} m='48px auto' fontFamily='Product Sans'>
      <Box
        color='#283e77'
        textAlign='center'
        fontSize={36}
        mb={2}
        fontWeight={700}
      >
        {details.title}
      </Box>
      <div dangerouslySetInnerHTML={{ __html: details.content }} />
      <MetaTags
        title={details?.title}
        description={details?.meta_description}
        keywords={details?.meta_keyword}
      />
    </Box>
  ) : (
    <NotFound />
  );
};

export default CmsPage;
