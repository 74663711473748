import React from "react";
import Collapse from "@material-ui/core/Collapse";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCategoryLink, getCategoryByParentId } from "../../../helpers/common";
import "./index.css";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useParams } from "react-router-dom";


const FirstLevelMenu = ({ categories, category, handleClick }) => {
  let subCats = getCategoryByParentId(categories, category.id);
  const { categoryId } = useParams();
  
  if (category.total_sub_categories === 0) {
    return (
      <MenuItem className={`${category.id === parseInt(categoryId) ? 'active' : ''}`}>
        {category.name}
        <Link to={getCategoryLink(category)} />
      </MenuItem>
    );
  } else {
    return (
      <SubMenu title={category.name}>
        {subCats.map((category) => (
          <SecondLevelMenu
            handleClick={handleClick}
            key={"SecondLevelMenu-" + category.id}
            categories={categories}
            category={category}
          />
        ))}
      </SubMenu>
    );
  }
};

const SecondLevelMenu = ({ categories, category, handleClick }) => {
  let subCats = getCategoryByParentId(categories, category.id);
  const { categoryId } = useParams();


  if (category.total_sub_categories === 0) {
    return (
      <MenuItem className={`${category.id === parseInt(categoryId) ? 'active' : ''}`}>
        {category.name}
        <Link onClick={handleClick} to={getCategoryLink(category)} />
      </MenuItem>
    );
  } else {
    return (
      <SubMenu title={category.name}>
        {subCats.map((category) => (
          <ThirdLevelMenu
            key={"ThirdLevelMenu-" + category.id}
            category={category}
            handleClick={handleClick}
          />
        ))}
      </SubMenu>
    );
  }
};

const ThirdLevelMenu = ({ category, handleClick }) => {
  const { categoryId } = useParams();

  return (
    <MenuItem className={`${category.id === parseInt(categoryId) ? 'active' : ''}`}>
      {category.name}
      <Link onClick={handleClick} to={getCategoryLink(category)} />
    </MenuItem>
  );
};

export default function CategoriesSec() {
  const categories = useSelector((state) => state.categories.mainCategories);

  // where parent is Main.
  const level1Categories = getCategoryByParentId(categories, 1);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="categories-list">
      <div className="web-category-sec">
        <ProSidebar>
          <Menu>
            {level1Categories.map((category, index) => (
              <div
                key={"level1Categories-" + category.id}
              >
                <FirstLevelMenu
                  handleClick={handleClick}
                  categories={categories}
                  category={category}
                />
              </div>
            ))}
          </Menu>
        </ProSidebar>
      </div>

      
      <div className="mobile-category-sec">
        <div className="all-category-btn" onClick={handleClick}>
          <div className="d-flex align-items-center">
            <i className="fa fa-list" aria-hidden="true"></i>
            <h6>{open ? "Hide categories" : "See All Categories"}</h6>
          </div>
        </div>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <ProSidebar>
            <Menu>
              {level1Categories.map((category, index) => (
                <div
                  key={"level1Categories-" + category.id}
                >
                  <FirstLevelMenu
                    handleClick={handleClick}
                    categories={categories}
                    category={category}
                  />
                </div>
              ))}
            </Menu>
          </ProSidebar>
        </Collapse>
      </div>
    </div>
  );
}
