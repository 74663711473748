
import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import ShippingProductDisplay from '../Shipping/ShippingProductDisplay';
import CheckoutDetailsDisplay from './CheckoutDetailsDisplay'
import { useNavigate, useLocation } from 'react-router-dom';

export default function Checkout() {
  const location = useLocation();
  const data = location?.state?.order;
  const navigate = useNavigate();

  if (!location.state || !location.state.order) {
    navigate("/cart");
    return false;
  }

  return (
    <Box maxWidth={1200} width="100%" p={3} m="48px auto" overflow="hidden">
      <Grid container spacing={10}>
        <Grid item md={6}>
          <Typography variant="h5" color="textSecondary" style={{ marginBottom: 32 }}>Checkout Details</Typography>
          <CheckoutDetailsDisplay customer={data.customer} />
        </Grid>
        <ShippingProductDisplay deliveryCharges={data?.order?.delivery_charges} />
      </Grid>
    </Box>
  )
}
