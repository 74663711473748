import React, { useState, useEffect, useCallback } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Box } from "@material-ui/core";
import CommonButton from "../Common/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showErrorSnackbar } from "../../actions/snackbar";
import { HomeOutlined, BusinessOutlined } from "@material-ui/icons";

const ShippingDetails = ({
  classes,
  handleAddressSubmit,
  isLoading,
  customer_remarks,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);

  const [expandedPanel, setExpandedPanel] = useState("home-form-panel");

  const isHomePanelExpended = expandedPanel === "home-form-panel";

  useEffect(() => {
    window.scrollTo({
      top: 150,
      behavior: "smooth",
    });
  }, [expandedPanel]);

  const [customer, setCustomer] = useState({
    name: "",
    address: "",
    city: "",
    country: "India",
    state: "",
    pin_code: "",
    mobile_number: "",
    company_name: "",
    gst_aadhaar_number: "",
    transport_name: "",
    transport_number: "",
  });

  useEffect(() => {
    if (profile) {
      setCustomer((currVal) => {
        return {
          ...currVal,
          address: profile.address ? profile.address : "",
          name: profile.name ? profile.name : "",
          mobile_number: profile.mobile_number ? profile.mobile_number : "",
          pin_code: profile.pin_code ? profile.pin_code : "",
          city: profile.city ? profile.city : "",
          district: profile.district ? profile.district : "",
          state: profile.state ? profile.state : "",
          customer_remarks: customer_remarks || "",
        };
      });
    }
  }, [profile, customer_remarks]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (customer.state === "") {
        dispatch(showErrorSnackbar("Please select your state"));
        return;
      }

      const data = {
        customer: {
          name: customer?.name,
          address: customer?.address,
          city: customer?.city,
          country: customer?.country ,
          state: customer?.state,
          pin_code: customer?.pin_code,
          mobile_number: customer?.mobile_number,
          AdditionalFields: {
            company_name: customer?.company_name,
            gst_aadhaar_number: customer?.gst_aadhaar_number,
            transport_name: customer?.transport_name,
            transport_number: customer?.transport_number,
          }
        },
        coupon_code: customer.coupon_code,
        customer_remarks: customer.customer_remarks,
      };

      handleAddressSubmit(data);
    },
    [handleAddressSubmit, customer, dispatch]
  );

  const handleChange = (panel) => (_, newExpanded) => {
    setExpandedPanel(newExpanded ? panel : expandedPanel);
  };

  const onChange = (e) => {
    setCustomer({ ...customer, [e.target.name]: e.target.value });
  };

  const formFields = (formType = "home") => {
    return (
      <div>
        <PhoneInput
          value={"+91" + customer.mobile_number}
          onChange={(val) => setCustomer({ ...customer })}
          countryCodeEditable={false}
          country={"in"}
          masks={{ in: ".........." }}
          preferredCountries={["in"]}
          inputStyle={{ width: "100%" }}
          specialLabel='Mobile'
          disabled
        />
        <TextField
          fullWidth
          variant='outlined'
          value={profile.email}
          name='email'
          label='Email'
          className={classes.shippingField}
        />
        <TextField
          required
          fullWidth
          variant='outlined'
          value={customer.name}
          name='name'
          onChange={onChange}
          label='Your Name'
          className={classes.shippingField}
        />

        {formType === "business" && (
          <>
            <TextField
              required
              fullWidth
              variant='outlined'
              value={customer.company_name}
              name='company_name'
              onChange={onChange}
              label='Your Company Name'
              className={classes.shippingField}
            />

            <TextField
              required
              fullWidth
              variant='outlined'
              value={customer.gst_aadhaar_number}
              name='gst_aadhaar_number'
              onChange={onChange}
              label='GST No./Adhaar Card No.'
              className={classes.shippingField}
            />
          </>
        )}

        <TextField
          required
          fullWidth
          variant='outlined'
          value={customer.address}
          name='address'
          onChange={onChange}
          label='Shipping Address'
          className={classes.shippingField}
        />
        <TextField
          required
          fullWidth
          variant='outlined'
          value={customer.city}
          name='city'
          onChange={onChange}
          label='Shipping City'
          className={classes.shippingField}
        />

        <Grid container spacing={3} style={{ marginTop: 0 }}>
          <Grid item md={4}>
            <CountryDropdown
              value={customer.country}
              onChange={(val) => setCustomer({ ...customer, country: val })}
              className={classes.regions}
            />
          </Grid>

          <Grid item md={4}>
            <RegionDropdown
              country={customer.country}
              value={customer.state || "Select a State"}
              onChange={(val) => setCustomer({ ...customer, state: val })}
              className={classes.regions}
              blankOptionLabel='Select State'
              defaultOptionLabel='Select State'
            />
          </Grid>

          <Grid item md={4}>
            <TextField
              required
              variant='outlined'
              label='Pin code'
              name='pin_code'
              placeholder='Your Pin code'
              value={customer.pin_code}
              inputProps={{ length: 6 }}
              onChange={onChange}
            />
          </Grid>
        </Grid>

        {formType === "business" && (
          <>
            <TextField
              style={{ marginTop: 30 }}
              required
              fullWidth
              variant='outlined'
              value={customer.transport_name}
              name='transport_name'
              onChange={onChange}
              label='Transport Name'
              placeholder='Transport Name'
              className={classes.shippingField}
            />

            <TextField
              required
              fullWidth
              variant='outlined'
              label='Transport Number'
              name='transport_number'
              placeholder='Transport Number'
              value={customer.transport_number}
              onChange={onChange}
              className={classes.shippingField}
            />
          </>
        )}

        <TextField
          fullWidth
          variant='outlined'
          value={customer.customer_remarks}
          name='customer_remarks'
          onChange={onChange}
          label='Your Remarks'
          className={classes.shippingField}
        />
      </div>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Accordion
          expanded={isHomePanelExpended}
          onChange={handleChange("home-form-panel")}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            aria-controls='home-form-paneld-content'
            id='home-form-paneld-header'
          >
            <Typography className={classes.accordionTitle}>
              <HomeOutlined /> For Home Use
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {isHomePanelExpended && formFields()}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={!isHomePanelExpended}
          onChange={handleChange("business-form-panel")}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            aria-controls='business-form-paneld-content'
            id='business-form-paneld-header'
          >
            <Typography className={classes.accordionTitle}>
              <BusinessOutlined /> For Business Use
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!isHomePanelExpended && formFields("business")}
          </AccordionDetails>
        </Accordion>

        <Box display='flex' justifyContent='space-between'>
          <Box mt={3} display='flex'>
            <CommonButton
              rounded
              style={{ backgroundColor: "#d5d5d5", width: 150 }}
              onClick={() => navigate("/cart")}
            >
              Go Back
            </CommonButton>
          </Box>
          <Box mt={3} display='flex'>
            <CommonButton rounded type='submit' loading={isLoading} width={200}>
              Checkout
            </CommonButton>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ShippingDetails;
