import { Box, Divider, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { placeOrder } from '../../actions/orders';
import { showErrorSnackbar } from '../../actions/snackbar';
import { verifyPayment } from '../../api/orders';
import { imageUrl } from '../../helpers/common';
import CommonButton from '../Common/CommonButton';
import ShippingDetailsDisplay from './ShippingDetailsDisplay';
import ShippingDetailsForm from './ShippingDetailsForm';
import useStyles from './styles';


export default function Orders() {
  const classes = useStyles();
  const [customer, setCustomer] = useState({
    name: '',
    address: '',
    mobile_number: '',
    city: '',
    country: 'India',
    state: '',
    zip_code: '',
    additional_phone_number: ''
  });
  const [products, setProducts] = useState([]);
  const [coupon, setCoupon] = useState('');
  const profile = useSelector(state => state.auth.profile);
  const tempOrder = useSelector(state => state.orders.tempOrder);
  const shippingDetails = useSelector(state => state.orders.shippingDetails);
  const [isEdit, setEdit] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('shivamCartProducts'));
    if (items && Array.isArray(items)) setProducts(items);
  }, []);

  useEffect(() => {
    if (profile) setCustomer(profile);
  }, [profile]);

  useEffect(() => {
    const customer = localStorage.getItem('customerShippingDetails') && JSON.parse(localStorage.getItem('customerShippingDetails'));
    if (customer) setCustomer(customer);
  }, [isEdit]);

  useEffect(() => {
    if (!tempOrder)
      navigate.replace('/cart');
  }, [tempOrder, navigate]);

  const getTotal = () => {
    return products?.reduce((acc, product) => acc + product.selling_price * product.quantity, 0);
  }

  const getDiscount = () => {
    return products?.reduce((acc, product) => acc + product.mrp * product.quantity, 0) - getTotal();
  }

  const onChange = (e) => {
    setCustomer({ ...customer, [e.target.name]: e.target.value })
  }

  const getRazpOption = () => ({
    key: "rzp_test_7ZWQ9b9WBOyxIn", // This is the razor pay api key (you can get this value from settings using key razorpay_api_key)
    amount: parseInt(tempOrder?.total_amount, 10) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR", // this value will come from settings currency
    name: "Home Shop", // this value will come from settings company_name
    description: "Home Shop Purchase", // this value will come from settings shoping_description
    image: require('../../assets/images/razorpay_logo.png'), // this value will come from settings company_logo
    order_id: tempOrder?.order_id,
    handler: function (response) {
      // here call the api /orders/verify-payment
      verifyPayment({ order_id: tempOrder.order_id, payload: response }).then(json => {
        if (json.data.payment_status !== 'Failed') {
          localStorage.removeItem('shivamCartProducts');
          navigate('/my-orders');
        } else {
          dispatch(showErrorSnackbar('Payment was not successful! Please try again!'));
        }
        setLoading(false);
      });

    },
    prefill: {
      name: shippingDetails.name, // customer name
      email: shippingDetails.email, // customer email address
      contact: shippingDetails.mobile_number // customer mobile number
    },
    notes: {
      address: shippingDetails.address // customer address
    },
    theme: {
      color: "#283e77" // this value will come from settings razorpay_theme_color
    }
  });

  const saveShippingDetails = (e) => {
    e.preventDefault();

    setLoading(true);

    if (isEdit) {
      dispatch(placeOrder({ order_id: tempOrder?.order_id, customer })).then((ack) => {
        localStorage.setItem('customerShippingDetails', JSON.stringify(customer));
        if (ack) setEdit(false);
        setLoading(false);
      });
    } else {
      const rzpInstance = new window.Razorpay(getRazpOption());
      rzpInstance.open();
    }
  }

  return (
    <Box maxWidth={1200} p={3} m="48px auto" overflow="hidden">
      <form onSubmit={saveShippingDetails}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <Typography variant="h5" color="textSecondary" style={{ marginBottom: 32 }}>Shipping Details</Typography>

            {!isEdit ? <ShippingDetailsDisplay customer={customer} setEdit={setEdit} /> : <ShippingDetailsForm customer={customer} setCustomer={setCustomer} classes={classes} onChange={onChange} />}
          </Grid>

          <Grid item md={6} className={classes.items}>
            {products.map(product =>
              <Box
                key={product.id}
                my={3}
                p={3}
                display="flex"
                onClick={() => navigate(`/product/${product.id}`)}
                alignItems="center"
              >
                <Box width={80} height={80} borderRadius={5} border="1px solid transparent">
                  <img className={classes.images} src={imageUrl(product.main_image, 'products')} alt="cart-item" />
                </Box>

                <Box flex={1} ml={2}>
                  <Box fontSize={24} fontWeight={700}>
                    {product.name}
                  </Box>
                  <Box fontSize={18} display="flex" color="#666">
                    <Box>&#x20b9;{product.selling_price} x {product.quantity}</Box>
                  </Box>
                </Box>

                <Box fontSize={24} mx={3} fontWeight={700}>&#x20b9;{product.selling_price * product.quantity}</Box>
              </Box>)}

            <Divider />

            <Box my={2} display="flex" alignItems="center">
              <TextField
                variant="outlined"
                label="Coupon Code"
                placeholder="Enter coupon code"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                style={{ marginRight: 16 }}
              />
              <CommonButton loading={false} variant="contained" style={{ height: 40 }} color="primary">Apply</CommonButton>
            </Box>

            <Divider />

            <Box display="flex" my={2} justifyContent="space-between" fontSize={18}>
              <Box color="#666">Subtotal</Box>
              <Box fontWeight={700}>&#x20b9;{getTotal()}</Box>
            </Box>

            <Box display="flex" my={2} justifyContent="space-between" fontSize={18}>
              <Box color="#666">Total Discount</Box>
              <Box fontWeight={700}>- &#x20b9;{getDiscount()}</Box>
            </Box>

            <Box display="flex" my={2} justifyContent="space-between" fontSize={18}>
              <Box color="#666">Courier Charges</Box>
              <Box fontWeight={700}>&#x20b9;{getTotal()}</Box>
            </Box>

            <Divider />

            <Box display="flex" my={2} justifyContent="space-between" fontSize={20}>
              <Box fontWeight={700}>Total</Box>
              <Box color="#666">&#x20b9;{tempOrder?.total_amount}</Box>
            </Box>
          </Grid>
        </Grid>

        <Box mt={3} display="flex" justifyContent="flex-end">
          <CommonButton rounded type="submit" loading={isLoading} width={200}>{!isEdit && shippingDetails ? 'Pay Now' : 'Place Order'}</CommonButton>
        </Box>
      </form>
    </Box>
  )
}
