import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBanners } from "../../actions/banners";
import { getCategory } from "../../actions/categories";
import { getContents } from "../../actions/contents";
import { getProducts } from "../../actions/products";
import { LOAD_CART_DATA } from "../../constants/actionTypes";
import MetaTags from "../MetaTags";
import FeaturedProducts from "./FeaturedProducts";
import HeaderCarousel from "./HeaderCarousel";
import HomeCategories from "./HomeCategories";
import HotProducts from "./HotProducts";
import useStyles from "./styles";

function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.contents?.frontendData) || [];

  const homePageTitle = settings.find(
    (item) => item.key === "homepage_title"
  )?.value;
  const homePageDescription = settings.find(
    (item) => item.key === "homepage_meta_description"
  )?.value;
  const homepageMetaKeywords = settings.find(
    (item) => item.key === "homepage_meta_keywords"
  )?.value;

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getProducts({ product_labels: "Hot" }, 12, 1));
    dispatch(getProducts({ product_labels: "Offer" }), 10, 1);
    dispatch(getProducts({ product_labels: "Featured" }), 10, 1);
    dispatch(getBanners("Homepage Banners"));
    dispatch(getContents("testimonials"));
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: LOAD_CART_DATA });
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <HeaderCarousel />


      <HotProducts />

      <HomeCategories />

      <FeaturedProducts />

      <MetaTags
        title={homePageTitle}
        isHomePage={true}
        description={homePageDescription}
        keyword={homepageMetaKeywords}
      ></MetaTags>
    </Box>
  );
}

export default Home;
