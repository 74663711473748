import React from "react";

import MTags from "react-meta-tags";

function MetaTags(props) {
  const { title, description, keywords, image, isHomePage = false } = props;
  return (
    <>
      <MTags>
        <title>{title} {!isHomePage ? "| Home Shop" : null} </title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        <meta property="og:title" content={title} />
        <meta property="og:image" content={image} />
      </MTags>
    </>
  );
}

export default MetaTags;
