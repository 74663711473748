import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  categoryImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  overlayText: {
    zIndex: 99,
    background: "rgba(215,25,15,0.7)",
    width: "100%",
    "& > h6": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  contentRoot: {
    cursor: "pointer",
    "&:hover": {
      transition: "all ease-in-out 0.5s",
      transform: "scale(1.05)"
    },
    margin: "auto"
  }
}));

export default useStyles;
