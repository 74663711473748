import { Box, Grid, TextField, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { forgetEmailPassword } from '../../actions/customer';
import { showErrorSnackbar, showSuccessSnackbar } from '../../actions/snackbar';
import { forgetEmailPasswordOtpCheck } from '../../api/customer';
import CommonButton from '../Common/CommonButton';
import ResendOtp from '../ResendOtp';
import useStyles from './styles';

export default function ForgetPassword(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [checkOtp, setOtpCheck] = useState(false);
  const profile = useSelector(state => state.auth.profile);
  const [email, setEmail] = useState('' || profile.email);
  const [otp, setOtp] = useState('');

  const sendOtp = () => {
    dispatch(forgetEmailPassword({ email: { email: email } })).then((ack) => {
      setLoading(false);
      if (ack) {
        setOtpCheck(true);
      }
    })
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (email === '' || (checkOtp && otp === '')) {
      return;
    }

    if (checkOtp) {
      forgetEmailPasswordOtpCheck(email, otp).then((json) => {
        setLoading(false);
        dispatch(showSuccessSnackbar(`New Password has been sent to your email ${email}`));
        navigate('/login');
      }).catch((error) => {
        setLoading(false);

        if (error.message.indexOf('400') !== -1) {
          dispatch(showErrorSnackbar('OTP is invalid !'));
        } else {
          dispatch(showErrorSnackbar('Something went wrong! Please trye again!'));
        }
      })
    } else {
      sendOtp();
    }
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={8}>
      <Box fontSize={24} fontWeight={700} color="#666" mt={5} mb={2}>
        Forget Password
      </Box>
        {!checkOtp ? (<Box className={classes.para} >You will get an OTP on your email to reset your password.</Box>) : (<Box textAlign='center' fontSize={14} mt={1} mb={2}>Enter the OTP sent to your email <span style={{fontWeight: '600'}}>{email}</span></Box>)}

      <form onSubmit={onSubmit}>
        <Box width={480} className="input-sec">
          {!checkOtp ? <Grid container alignItems="center">
            <Grid item xs={3} className="input-lable">
              <Box mr={1}>Email Address*</Box>
            </Grid>
            <Grid item xs={9}>
              <TextField
                placeholder="Enter your email address"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => setEmail(e.target.value)}
                style={{ width: 300 }}
              />

            </Grid>
          </Grid> : (
            <>
              <Box display="flex" justifyContent="center">
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={6}
                  isInputNum
                  separator={<span>-</span>}
                  inputStyle={classes.otpInputStyle}
                  focusStyle={{ borderColor: '#283e77' }}
                />
              </Box>
                <Box className={classes.resendBlock}>
              {resendLoading ? (<CircularProgress size={26} color="primary" className={classes.circularProgress} />):  (<ResendOtp maxTime={60} setResendLoading={setResendLoading} email={email}/>)}</Box>
            </>
          )}
          {/* {checkOtp && <Box textAlign="right" mt={2} mr={8}>
            {time !== 0 ? `Time elapsed ${millisToMinutesAndSeconds(time)}` : <Box onClick={sendOtp} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              Resend OTP
            </Box>}
          </Box>} */}
          
        </Box>

        <Box display="flex" justifyContent="center" mt={2}>
          <CommonButton rounded width={150} type="submit" loading={loading}>{checkOtp ? 'Verify OTP' : 'Get Password'}</CommonButton>
        </Box>
      </form>

      <Box my={2}>
        <Box component="span" mr={1}>Go to
          <Link to="/login" style={{ marginLeft: 10 }}>Login</Link>
        </Box>
      </Box>
    </Box >
  );
}

