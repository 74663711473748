import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography, Avatar, Divider } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateProfile } from '../../actions/customer';
import { getOrderDetails } from '../../actions/orders';
import { imageUrl } from '../../helpers/common';
import CommonButton from '../Common/CommonButton';
import { showErrorSnackbar } from '../../actions/snackbar';

export default function OrderDetails(props) {
  const { orderId } = useParams();
  const currentOrder = useSelector(state => state.orders.currOrder);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetails(orderId))
    }
  }, [dispatch, orderId]);

  const onReorder = (e) => {
    e.preventDefault();
    const productList = currentOrder.map(order => ({ ...order.product, quantity: order.quantity }));
    localStorage.setItem('shivamCartProducts', JSON.stringify(productList));
    setLoading(true);

    dispatch(updateProfile({ cart_data: JSON.stringify(productList) })).then((ack) => {
      setLoading(false);
      if (ack) navigate('/cart');
      else dispatch(showErrorSnackbar('Sorry! Products cannot be added!'))
    });
  }

  return (
    <Box p={3}>
      <Box m="auto" maxWidth={1200}>
        <Box fontSize={20} color="#666">Order Details</Box>
        <List>
          {currentOrder.map(({ id, product, total_amount, quantity }) => (<>
            <ListItem alignItems="flex-start" onClick={() => navigate(`/product/${product?.id}`)}>
              <ListItemAvatar>
                <Avatar
                  variant="rounded" alt={product?.name}
                  src={imageUrl(product?.main_image)}
                  style={{ width: 75, height: 75, marginRight: 20 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={<Box fontWeight={700} fontSize={20}>{product?.name}</Box>}
                secondary={
                  <Box display="flex" fontSize={16}>
                    <Box>
                      <Box component="span">Price : </Box>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        style={{ textDecoration: 'line-through' }}
                      >
                        {`Rs. ${product?.mrp} `}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        {` Rs. ${product?.selling_price}`}
                      </Typography>

                      <Box>{`Quantity : ${quantity}`}</Box>
                    </Box>
                    <Box ml="auto" fontSize={18}>{`Total Price : Rs. ${total_amount}`}</Box>
                  </Box>
                }
              />
            </ListItem>
            <Divider />
          </>))}
        </List>

        <Box display="flex" justifyContent="flex-end">
          <CommonButton rounded loading={loading} width={150} onClick={onReorder}>Reorder</CommonButton>
        </Box>
      </Box>
    </Box >
  )
}

