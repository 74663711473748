import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { submitNewReview } from "../../../actions/products";
import { showErrorSnackbar } from "../../../actions/snackbar";
import "../../../index.css";
import CommonButton from "../../Common/CommonButton";
import CustomHeading from "../../Common/CustomHeadings";
import useStyles from "./styles";
import { useEffect } from "react";

const RatingAndReview = ({ product }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const [error, setError] = useState({});
  const [writeReview, setWriteReview] = useState(false);
  const [review, setReview] = useState("");
  const [totalReviews, setTotalReviews] = useState(0);
  const [loading, setLoading] = useState(false);
  const reviewList = useSelector((state) => state.products.reviews.reviews);
  const customer = useSelector((state) => state.auth.profile);

  useEffect(() => {
    setTotalReviews(
      product.total_1_stars +
        product.total_2_stars +
        product.total_3_stars +
        product.total_4_stars +
        product.total_5_stars
    );
  }, [product]);

  const submitReview = () => {
    if (rating === 0) {
      setError({ rating: "Please add a rating" });
      return;
    }

    setError({});
    setLoading(true);
    dispatch(
      submitNewReview(
        { product_id: product.id, review, rating_value: rating },
        customer
      )
    ).then((ack) => {
      setLoading(false);
      if (!ack) dispatch(showErrorSnackbar("Sorry something went wrong !"));
      else setWriteReview(false);
    });
  };
  const avgRating =
    totalReviews === 0
      ? 0
      : (product.total_1_stars * 1 +
          product.total_2_stars * 2 +
          product.total_3_stars * 3 +
          product.total_4_stars * 4 +
          product.total_5_stars * 5) /
        totalReviews;

  const onWriteReview = () => {
    if (!customer.mobile_number) {
      navigate("/login");
      dispatch(showErrorSnackbar("You need to login first"));
    } else {
      setWriteReview(true);
    }
  };

  if (!product) {
    return null;
  }

  return (
    <Box bgcolor='#eee' px={3} pt={1} pb={4} mt={5} borderRadius={2}>
      <Box>
        <CustomHeading
          text='PRODUCT RATING'
          letterSpacing={1.1}
          fontSize={14}
          color='#666'
          fontWeight={700}
        />

        <Grid container justifyContent='space-around'>
          <Grid
            item
            sm={3}
            style={{
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            {avgRating === 0 ? (
              <Box pt={3}>No Rating yet</Box>
            ) : (
              <>
                <Box fontWeight={700} fontSize={44} component='span'>
                  {avgRating}
                </Box>
                <Box
                  fontWeight={700}
                  fontSize={24}
                  color='#666'
                  component='span'
                >
                  /5
                </Box>
                <Box>
                  <Rating
                    name='product-rating'
                    value={avgRating}
                    precision={0.1}
                    onChange={(_, v) => setRating(v)}
                    classes={{ iconFilled: classes.ratingIcon }}
                    readOnly
                  />
                </Box>

                <Box
                  color='#666'
                  fontWeight={700}
                >{`Based on ${product.average_stars} rating`}</Box>
              </>
            )}
          </Grid>

          <Grid item sm={3}>
            {[5, 4, 3, 2, 1].map((val) => (
              <Box key={val} display='flex' alignItems='center'>
                <Box color='#666' component='span' mr={1}>
                  {product[`total_${val}_stars`]}
                </Box>
                <LinearProgress
                  variant='determinate'
                  value={
                    totalReviews === 0
                      ? 0
                      : product[`total_${val}_stars`] / totalReviews
                  }
                  style={{ width: 90, margin: "0 6px" }}
                />
                <Rating
                  name='product-rating'
                  value={val}
                  size='small'
                  onChange={(_, v) => setRating(v)}
                  classes={{ iconFilled: classes.ratingIcon }}
                  readOnly
                />
              </Box>
            ))}
          </Grid>

          {!writeReview ? (
            <>
              <Divider flexItem orientation='vertical' />

              <Grid item sm={4} className={classes.writeReview}>
                <Box>
                  <Box display='flex' justifyContent='space-between' my={2}>
                    <Box fontWeight={700}>Rate product</Box>
                    <Rating
                      size='small'
                      name='rate-product'
                      value={rating}
                      className={classes.ratingIcon}
                    />
                  </Box>
                  <CommonButton
                    rounded
                    variant='contained'
                    onClick={onWriteReview}
                    style={{
                      width: "100%",
                      background: "#999",
                      color: "#fff",
                    }}
                  >
                    WRITE REVIEW
                  </CommonButton>
                </Box>
              </Grid>
            </>
          ) : (
            <Grid item sm={12} className={classes.reviewForm}>
              <Divider />
              <Box fontSize={16} fontWeight={700} color='#666'>
                Rating :{" "}
              </Box>
              <Rating
                name='rate-product'
                value={rating}
                className={classes.ratingIcon}
                onChange={(e, v) => setRating(v)}
              />
              <TextField
                variant='outlined'
                fullWidth
                multiline
                rows={4}
                placeholder='Write your review'
                value={review}
                onChange={(e) => setReview(e.target.value)}
                style={{ backgroundColor: "#fff" }}
              />
              {error.rating && (
                <Box color='#f00' fontFamily='Product Sans'>
                  {error.rating}
                </Box>
              )}
              <Box display='flex'>
                <CommonButton
                  rounded
                  variant='contained'
                  color='primary'
                  loading={loading}
                  style={{ width: 150, marginRight: 10 }}
                  onClick={submitReview}
                >
                  Submit Review
                </CommonButton>
                <CommonButton
                  rounded
                  variant='contained'
                  style={{ width: 150, background: "#999", color: "#fff" }}
                  onClick={() => setWriteReview(false)}
                >
                  Cancel
                </CommonButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      {reviewList?.length > 0 && (
        <Box fontWeight={700} color='#666'>
          PRODUCT RATING
        </Box>
      )}
      {reviewList?.map((review) => (
        <Box my={5} className={classes.ratingContainer}>
          <Box display='flex' justifyContent='space-between' mb={2}>
            <Box display='flex' alignItems='center' fontSize={18}>
              <Box fontWeight={700}>{review.rating_value}</Box>
              <Box color='#666' mr={2}>
                /5
              </Box>
              <Rating
                name='product rating'
                value={review.rating_value}
                className={classes.ratingIcon}
                readOnly
              />
            </Box>

            <Box fontStyle='italic' color='#666'>{`${
              review.customer?.name || "Anonymous"
            } posted on ${moment(review.created_at).fromNow()}`}</Box>
          </Box>

          <Box fontWeight={700} fontSize={16}>
            {review.review}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default RatingAndReview;
