import { Box, Grid } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getContents } from "../../../actions/contents";
import { getMenus } from "../../../actions/menus";
import { getSettingsInfo } from "../../../actions/settings";
import useStyles from "./styles";
import { getCustomerDetails } from "../../../actions/customer";
import { customerId_local_storage_key } from "../../../helpers/common";

export default function Index() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const frontendData = useSelector((state) => state.contents.frontendData);
  const menus = useSelector((state) => state.menus);

  useEffect(() => {
    dispatch(getMenus("footer-quick-links-menu"));
    dispatch(getMenus("footer-information-menu"));
    dispatch(getSettingsInfo());
    dispatch(getContents("homepage"));
    let customerId = localStorage.getItem(customerId_local_storage_key);
    if (customerId) {
      dispatch(getCustomerDetails(customerId));
    }
  }, [dispatch]);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const goToStaticPage = (data) => {
    navigate(data.url);
    window.scrollTo({
      top: 50,
      behavior: "smooth",
    });
  };

  const social_links_facebook = frontendData?.find(
    (data) => data.key === "social_links_facebook"
  )?.value;
  const social_links_instagram = frontendData?.find(
    (data) => data.key === "social_links_instagram"
  )?.value;
  const social_links_youtube = frontendData?.find(
    (data) => data.key === "social_links_youtube"
  )?.value;

  const company_address = frontendData?.find(
    (data) => data.key === "company_address"
  )?.value;

  const company_contact_number = frontendData?.find(
    (data) => data.key === "company_contact_number"
  )?.value;

  const company_email_address = frontendData?.find(
    (data) => data.key === "company_email_address"
  )?.value;

  const copyright_message = frontendData?.find(
    (data) => data.key === "footer_copyright_message"
  )?.value;

  return (
    <Box className={classes.root}>
      <Box
        className={classes.footerBackground}
        p={3}
        display='flex'
        justifyContent='center'
      >
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item md={3} sm={6} xs={12}>
            <Box fontSize={24} className={classes.footerMenuHeader}>
              Contact Info
            </Box>
            <Box display='flex' color='#eee' my={2} maxWidth={240}>
              {company_address}
            </Box>

            <Box display='flex' maxWidth={240}>
              <a
                className={classes.footerLinks}
                href={`tel: ${company_contact_number}`}
              >
                {company_contact_number}
              </a>
            </Box>

            <Box display='flex' maxWidth={240}>
              <a
                className={classes.footerLinks}
                href={`mailto: ${company_contact_number}`}
              >
                {company_email_address}
              </a>
            </Box>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <Box fontSize={24} className={classes.footerMenuHeader}>
              Quick Links
            </Box>
            {menus?.footerQuickLinksMenu?.map((data) => (
              <Box
                key={data.id}
                display='flex'
                className={classes.footerMenuItem}
                onClick={() => goToStaticPage(data)}
              >
                {data.name}
              </Box>
            ))}
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <Box fontSize={24} className={classes.footerMenuHeader}>
              Information
            </Box>
            {menus?.footerInformationMenu?.map((data) => (
              <Box
                key={data.id}
                className={classes.footerMenuItem}
                onClick={() => goToStaticPage(data)}
              >
                {data.name}
              </Box>
            ))}
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <Box fontSize={24} className={classes.footerMenuHeader}>
              Social
            </Box>

            <Box
              onClick={() => openInNewTab(social_links_facebook)}
              className={classes.footerMenuItem}
              display={"flex"}
              alignItems='center'
            >
              <FacebookIcon className={classes.socialIcons} />
              Like us on Facebook
            </Box>

            <Box
              onClick={() => openInNewTab(social_links_instagram)}
              className={classes.footerMenuItem}
              display={"flex"}
              alignItems='center'
            >
              <InstagramIcon className={classes.socialIcons} />
              Follow us on Instagram
            </Box>

            <Box
              onClick={() => openInNewTab(social_links_youtube)}
              display={"flex"}
              alignItems='center'
              className={classes.footerMenuItem}
            >
              <YouTubeIcon className={classes.socialIcons} />
              Watch us on YouTube
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        py={1}
        className={classes.footerDarkStrip}
        color='#eee'
        fontSize={15}
      >
        {copyright_message}
      </Box>
    </Box>
  );
}
